<template>
  <CardsList
    :loading="loading"
    type="dish"
    :dark="true"
    :items="dishes"
    :itemsPerPage="itemsPerPage"
    @update-items-per-page="updateItemsPerPage"
  />
</template>

<script>
import CardsList from "@/components/CardsList.vue";
import DishService from "@/services/api/DishService";

export default {
  components: { CardsList },
  data() {
    return {
      dishes: [],
      loading: false,
      itemsPerPage: 8
    };
  },
  methods: {
    async getDishes() {
      this.loading = true;
      try {
        const dishes = await DishService.getDishes();
        this.dishes = dishes.dishes;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    }
  },
  mounted() {
    this.getDishes();
  }
};
</script>
