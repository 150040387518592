import RequestService from "@/services/utils/RequestService";

export default {
  registerChef(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/chefs/register",
      data: payload
    });
  },
  loginChef(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/chefs/login",
      data: payload
    });
  },
  registerUser(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/users/register",
      data: payload
    });
  },
  loginUser(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/users/login",
      data: payload
    });
  },
  getOtpChallenge(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/otp",
      data: payload
    });
  },
  getOtpChallengeLoggedIn() {
    return RequestService({
      method: "get",
      url: "/authentication/otp"
    });
  },
  verifyOtpRmAccount(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/otp/verify/rm-account",
      data: payload
    });
  },
  verifyOtpChallenge(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/otp/verify",
      data: payload
    });
  },
  verifyOtpResetPassword(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/otp/verify/reset-password",
      data: payload
    });
  },
  resetPassword(payload) {
    return RequestService({
      method: "post",
      url: "/authentication/reset-password",
      data: payload
    });
  }
};
