<template>
  <v-container fluid>
    <v-alert
      v-if="(status === 'VERIFIED' || status === 'PENDING') && role === 'CHEF'"
      dismissible
      border="left"
      colored-border
      type="warning"
      elevation="2"
      class="py-2"
    >
      {{
        status === "VERIFIED"
          ? $t("warning.completeProfile")
          : $t("common.profilePending")
      }}
    </v-alert>
    <v-row row>
      <v-col cols="12" xl="3" lg="3" md="3">
        <v-row v-if="role === 'CHEF' && token && id === $route.params.chef" row>
          <v-col
            class="my-0"
            :cols="role === 'CHEF' && status === 'VERIFIED' ? 12 : 6"
          >
            <v-btn
              outlined
              v-if="status !== 'NOT_VERIFIED' && status !== 'PENDING'"
              @click="isChefInfoPopupOpen = true"
              color="info"
              width="100%"
              class="mx-1"
            >
              <v-icon small class="mx-2">fas fa-info</v-icon>
              {{ $t("chef.signUpForm.profile") }}
            </v-btn>
          </v-col>
          <v-col :cols="status !== 'NOT_VERIFIED' ? 6 : 12">
            <v-btn
              v-if="status === 'ACTIVE'"
              @click="isAddDishPopupOpen = true"
              color="primary"
              width="100%"
              class="mx-1"
            >
              <v-icon small class="mx-1">fas fa-plus</v-icon>
              {{ $t("dish.dishForm.addDish") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-card
          elevation="5"
          height="auto"
          scrollable
          outlined
          class="text-center mt-3"
          :loading="chefLoading"
        >
          <v-img
            height="280"
            :src="
              chef.img && chef.img.length > 0
                ? chefImageUrl(chef.img)
                : require('@/assets/becomeChef.webp')
            "
          >
            <v-btn
              v-if="role !== 'CHEF'"
              class="d-flex flex-row"
              color="#E0E0E0"
              icon
            >
              <v-icon
                @click="removeWishlist"
                v-if="onWishlist"
                color="primary"
                medium
                >fas fa-star</v-icon
              >
              <v-icon @click="addWishlist" v-else medium>far fa-star</v-icon>
            </v-btn>

            <v-btn
              v-if="
                chef.img &&
                  chef.img.length === 0 &&
                  role === 'CHEF' &&
                  token &&
                  id === $route.params.chef
              "
              @click="isChefInfoPopupOpen = true"
              text
              height="100%"
              width="100%"
            >
              <h2 v-if="chef.img && chef.img.length === 0" class="white--text">
                <b>{{ $t("common.uploadImage") }}</b>
              </h2>
            </v-btn>
          </v-img>

          <v-card-title class="py-0 mt-1 justify-center">
            {{ chef.name }}
          </v-card-title>

          <v-row align="center" justify="center" class="mx-0 mt-1">
            <v-rating
              :value="chef.stars"
              color="amber"
              dense
              half-increments
              readonly
              size="30"
              background-color="grey lighten-2"
            ></v-rating>
          </v-row>

          <div class="mb-1 subtitle-1">
            <v-card-text class="mb-0 pb-2">{{ chef.description }}</v-card-text>

            <v-card-text class="pb-0 mt-0">
              <v-chip
                color="primary"
                v-for="category in chef.categories"
                :key="category"
                class="mx-1 pa-2 mb-2 font-weight-bold"
                x-small
                outlined
              >
                {{ getCategoryText(category) }}
              </v-chip>
            </v-card-text>

            <v-card-subtitle
              v-if="status !== 'VERIFIED'"
              class="py-0 caption mt-2"
            >
              <v-row justify="center" align="center" class="ma-0 pa-0">
                <span class="grey--text text--lighten-1"
                  >{{ $t("chef.speaks") }}
                </span>
                <span
                  color="primary"
                  v-for="language in chef.languages"
                  :key="language"
                  class="mx-1 pa-2 font-weight-bold"
                >
                  {{ getLanguagesText(language) }}
                </span></v-row
              >
            </v-card-subtitle>

            <v-card-subtitle class="pb-0 mb-3 pt-2">
              <v-row justify="center" align="center" class="ma-0 pa-0">
                <span
                  v-if="status !== 'VERIFIED'"
                  class="py-1 caption grey--text text--lighten-1 mx-0"
                  >{{ $t("common.availability") }}
                </span>
                <span
                  v-if="chef.availability ? chef.availability.length === 7 : ''"
                  class="mx-1 px-2 font-weight-bold"
                  color="white"
                >
                  {{ $t("common.weekly") }}
                </span>
                <span
                  v-else
                  color="primary"
                  v-for="availability in chef.availability"
                  :key="availability"
                  class="mx-1 px-2 font-weight-bold"
                >
                  {{ getDayText(availability) }}
                </span>
              </v-row>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="9" lg="9" md="9">
        <CardsList
          :loading="dishesLoading"
          type="dish"
          :withChef="true"
          :items="dishes"
          :itemsPerPage="itemsPerPage"
          @update-items-per-page="updateItemsPerPage"
        />
      </v-col>
    </v-row>
    <DishForm
      :isOpen="isAddDishPopupOpen"
      @close-popup="isAddDishPopupOpen = false"
    />
    <ChefInfoForm
      :isOpen="isChefInfoPopupOpen"
      @close-popup="updateChefProfile"
    />
  </v-container>
</template>
<script>
import CardsList from "@/components/CardsList.vue";
import ChefService from "@/services/api/ChefService";
import DishService from "@/services/api/DishService";
import { mapFields } from "vuex-map-fields";
import UserService from "@/services/api/UserService";
import { mapMutations } from "vuex";
const DishForm = () => import("@/components/DishForm.vue");
const ChefInfoForm = () => import("@/components/ChefInfoForm.vue");

export default {
  name: "Chef",
  components: { CardsList, DishForm, ChefInfoForm },
  data() {
    return {
      isAddDishPopupOpen: false,
      isChefInfoPopupOpen: false,
      chef: {},
      dishes: [],
      chefLoading: false,
      dishesLoading: false,
      onWishlist: false,
      itemsPerPage: 8
    };
  },
  computed: {
    ...mapFields("auth", ["role", "status", "token", "id"]),
    ...mapFields("main", ["categories"]),
    ...mapFields("carts", ["wishlist"])
  },
  methods: {
    ...mapMutations("carts", ["addChefToWishlist", "removeChefFromWishlist"]),
    updateChefProfile() {
      this.isChefInfoPopupOpen = false;
      this.status === "ACTIVE" ? this.getChefById(this.$route.params.chef) : "";
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    removeWishlist() {
      if (this.token) {
        this.removeWishlistChef();
      }
      this.removeChefFromWishlist(this.chef.id);
      this.onWishlist = false;
    },
    async removeWishlistChef() {
      const formData = new FormData();
      try {
        await UserService.removeWishlistChefs(formData);
      } catch {}
    },
    async uploadWishlistChefs() {
      const formData = new FormData();
      try {
        await UserService.addWishlistChefs(formData);
      } catch {}
    },
    addWishlist() {
      if (this.token) {
        this.uploadWishlistChefs();
      }
      this.addChefToWishlist(this.chef.id);
      this.onWishlist = true;
    },
    async getChefById(id) {
      this.chefLoading = true;
      try {
        const chef = await ChefService.getChefById(id);
        this.chef = chef;
        this.getDishesByChefId(this.chef.id);
      } catch {
        this.$router.push({ name: "home" });
      } finally {
        this.chefLoading = false;
      }
    },
    async getChefProfile(id) {
      this.loading = true;
      try {
        const chefProfile = await ChefService.getChefProfile(id);
        this.chef = chefProfile;
        this.status === "ACTIVE" && this.chef.images.length !== 1
          ? (this.chef.images = [
              process.env.VUE_APP_BACKEND_URL +
                "/images/" +
                this.chef.images.filter(img => img !== "ID")
            ])
          : "";
      } catch {
      } finally {
        this.loading = false;
      }
    },
    getDayText(value) {
      return this.$t("lists.days")
        .filter(day => day.value === value)
        .map(x => x.text)[0];
    },
    getCategoryText(value) {
      return this.categories
        .filter(category => category.value === value)
        .map(x => x.text)[0];
    },
    getLanguagesText(value) {
      return this.$t("lists.languages")
        .filter(day => day.value === value)
        .map(x => x.text)[0];
    },
    async getDishesByChefId(id) {
      this.dishesLoading = true;
      try {
        const dishes =
          this.token &&
          this.role === "CHEF" &&
          this.$route.params.chef === this.id
            ? await DishService.getChefDishes()
            : await DishService.getDishesByChefId(id);
        this.dishes = dishes.dishes;
      } catch {
      } finally {
        this.dishesLoading = false;
      }
    },
    chefImageUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    }
  },
  created() {
    this.role === "CHEF" && this.token && this.status !== "ACTIVE"
      ? this.id === this.$route.params.chef
        ? this.getChefProfile(this.$route.params.chef)
        : this.getChefById(this.$route.params.chef)
      : this.getChefById(this.$route.params.chef);
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (this.wishlist.chefs)
      this.wishlist.chefs.filter(id => id === this.chef.id).length > 0
        ? (this.onWishlist = true)
        : (this.onWishlist = false);
  }
};
</script>
