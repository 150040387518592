<template>
  <div class="text-center ">
    <v-parallax
      :height="$vuetify.breakpoint.mdAndUp ? '630' : '540'"
      :src="require('@/assets/homeParralex.webp')"
    >
      <div style="position: relative; bottom: 8%;">
        <v-card-subtitle class="display-1 font-weight-bold px-0 pb-3">
          {{ $t("home.searchDiv.orderNow") }}
        </v-card-subtitle>
        <v-card-text class="pa-0 ma-0">
          <v-chip
            class="elevation-1 py-1 px-2 font-weight-bold "
            small
            label
            color="primary"
          >
            {{ $t("common.now") }}
          </v-chip>
        </v-card-text>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="5" md="2" lg="2" xl="2">
              <v-card
                color="transparent grey lighten-3 elevation-4"
                height="130"
                @click="$router.push({ name: 'dishes' })"
              >
                <v-row justify="center" class="text--center">
                  <v-icon large class="mt-6"
                    >fi fi-{{
                      $route.name === "dishes" ? "br" : "ts"
                    }}-restaurant
                  </v-icon></v-row
                >
                <v-row justify="center">
                  <v-card-title class="font-weight-bold">{{
                    $t("appHeader.tabs.dishes")
                  }}</v-card-title></v-row
                >
              </v-card>
            </v-col>
            <v-col cols="5" md="2" lg="2" xl="2">
              <v-card
                color="transparent grey lighten-3 elevation-4"
                height="130"
                @click="$router.push({ name: 'chefs' })"
              >
                <v-row justify="center" class="text--center">
                  <v-icon large class="mt-6"
                    >fi fi-{{ $route.name === "dishes" ? "br" : "tr" }}-hat-chef
                  </v-icon></v-row
                >
                <v-row justify="center">
                  <v-card-title class="font-weight-bold">{{
                    $t("appHeader.tabs.chefs")
                  }}</v-card-title></v-row
                >
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div style="position: relative; top: 17%;" @click="scrollSmooth">
        <v-icon color="grey lighten-2" size="40">fi fi-rr-angle-down</v-icon>
      </div>
    </v-parallax>
  </div>
</template>
<script>
export default {
  name: "SearchDiv",
  data() {
    return {
      isSelectLocationPopupOpen: false,
      selectedLocation: null,
      checkbox: "dishes"
    };
  },
  methods: {
    scrollSmooth() {
      window.scrollTo({ top: 660, behavior: "smooth" });
    }
  }
};
</script>
<style scoped>
.transparent {
  background-color: white !important;
  opacity: 0.65;
  border-color: transparent !important;
}
.display-1 {
  text-shadow: 1px 1px black;
  padding-top: 0%;
}
</style>
