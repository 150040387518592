<template>
  <div>
    <v-container fluid>
      <v-alert
        v-if="multipleChefs > 1"
        dismissible
        border="left"
        colored-border
        type="warning"
        elevation="2"
        class="py-2 mb-2 align-baseline"
      >
        <i18n class="py-0" path="warning.checkout">
          <template #multipleChefs>
            <span>{{ multipleChefs }}</span>
          </template>
        </i18n>
      </v-alert>
      <v-card style="margin:auto" tile elevation="0" max-width="700">
        <v-stepper non-linear class="mt-4" v-model="selectedStep">
          <v-stepper-header class="elevation-0">
            <v-stepper-step
              edit-icon="$complete"
              :editable="!loading"
              :complete="selectedStep > 1"
              step="1"
            >
              {{ $t("checkout.steps.summary") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              edit-icon="$complete"
              :editable="!loading"
              :complete="selectedStep > 2"
              step="2"
            >
              {{ $t("checkout.steps.things") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="!loading" step="3">
              {{ $t("checkout.steps.occasion") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content class="mt-0 pt-0 px-0 pb-0" step="1">
              <v-card
                tile
                class="px-1 elevation-0"
                min-height="360px"
                height="auto"
              >
                <v-card
                  v-for="(chef, index) in chefs"
                  :key="chef"
                  tile
                  elevation="0"
                  class="mb-6"
                  height="auto"
                >
                  <v-card-text class="ma-0 pa-0 mb-2">
                    <span class="ma-0 pa-0 mb-1 text-caption">
                      {{ $t("common.by") }}
                    </span>
                    <span
                      @click="
                        $router.push({
                          name: 'chef',
                          params: { chef: chef }
                        })
                      "
                      style="color: #7f0000; cursor: pointer;"
                      class="ma-0 pa-0 mb-1 subtitle-2 mx-1"
                    >
                      <u color="primary">{{ chefsName[index] }}</u>
                    </span>
                  </v-card-text>

                  <v-container fluid class="pa-0">
                    <v-card
                      v-for="dish in dishes.filter(dish => dish.chef === chef)"
                      :key="dish.id"
                      class="mb-2"
                      height="70px"
                      elevation="1"
                    >
                      <v-row row>
                        <v-col class="py-0" cols="3" lg="2" xl="2">
                          <v-card
                            :style="
                              $route.params.lang === 'en'
                                ? 'border-radius: 4px 0px 0px 4px;'
                                : 'border-radius: 0px 4px 4px 0px;'
                            "
                            elevation="0"
                            height="70px"
                          >
                            <v-img
                              height="70px"
                              :src="dishImageUrl(dish.imgs[0])"
                            >
                              <v-card
                                style="opacity: 0; cursor: pointer;"
                                class="mt-1 mb-0"
                                @click="
                                  $router.push({
                                    name: 'dish',
                                    params: { dish: dish.id }
                                  })
                                "
                                width="82%"
                                height="100%"
                              ></v-card>
                            </v-img>
                          </v-card>
                        </v-col>
                        <v-col class="pa-0" cols="9">
                          <v-row>
                            <v-col
                              class="pa-0 mx-1 mt-1"
                              cols="7"
                              lg="8"
                              xl="8"
                            >
                              {{
                                $vuetify.breakpoint.mdAndUp
                                  ? dish.name
                                  : dish.name.slice(0, 19)
                              }}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="pa-0 mt-1 mx-0" cols="2">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <p
                                    v-bind="attrs"
                                    v-on="on"
                                    style="color: grey;"
                                    class="ma-0 pa-0 mx-0 text-caption font-weight-thin"
                                  >
                                    <v-icon color="grey lighten-1" x-small
                                      >fas fa-stopwatch</v-icon
                                    >
                                    {{
                                      dish.prepTime >= 60
                                        ? (dish.prepTime / 60).toFixed(1)
                                        : dish.prepTime
                                    }}
                                    {{
                                      dish.prepTime >= 60
                                        ? $t("time.h")
                                        : $t("time.m")
                                    }}
                                  </p>
                                </template>
                                <span class="ma-0 pa-0"
                                  >{{ prepTime(dish.prepTime) }}
                                  {{ $t("dish.prepareDish") }}</span
                                >
                              </v-tooltip>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="pa-0 mx-4 mt-0 mx-lg-0" cols="1">
                              <v-btn
                                color="grey lighten-2"
                                @click="
                                  ConfirmRemoveDishCheckout(dish.name, dish.id)
                                "
                                icon
                                x-small
                              >
                                <v-icon x-small>fas fa-times</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row class="py-0">
                            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3">
                              <v-row>
                                <v-rating
                                  :value="dish.stars"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="28"
                                  background-color="grey lighten-2"
                                ></v-rating>
                              </v-row>
                            </v-col>
                            <v-spacer
                              v-if="$vuetify.breakpoint.mdAndUp"
                            ></v-spacer>

                            <v-col
                              :class="
                                $route.params.lang === 'en'
                                  ? 'pa-0 mt-2 ml-2'
                                  : 'pa-0 mt-2 mr-2'
                              "
                              cols="6"
                              md="4"
                              lg="4"
                              xl="4"
                            >
                              <v-icon
                                @click="
                                  changePersons(
                                    dish.id,
                                    dish.selectedNumberOfPersons,
                                    false
                                  )
                                "
                                :disabled="
                                  dish.selectedNumberOfPersons <=
                                  dish.minNumberOfPersons
                                    ? true
                                    : false
                                "
                                color="primary"
                                small
                              >
                                far fa-minus-square
                              </v-icon>
                              <span class="mx-2">
                                {{ dish.selectedNumberOfPersons }}
                                {{
                                  dish.selectedNumberOfPersons === 1
                                    ? $t("common.person")
                                    : $t("common.persons")
                                }}
                              </span>
                              <v-icon
                                @click="
                                  changePersons(
                                    dish.id,
                                    dish.selectedNumberOfPersons,
                                    true
                                  )
                                "
                                :disabled="
                                  dish.selectedNumberOfPersons >=
                                  dish.maxNumberOfPersons
                                    ? true
                                    : false
                                "
                                color="primary"
                                small
                              >
                                far fa-plus-square
                              </v-icon>
                            </v-col>
                            <v-col class="pa-0 mt-2 text-end mx-0" cols="4">
                              {{ dish.price * dish.selectedNumberOfPersons }}
                              {{ $t("common.currency") }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-divider
                      v-if="chefs.length > 1"
                      class="my-0 py-0"
                    ></v-divider>

                    <v-sheet
                      v-if="chefs.length > 1"
                      class="d-flex mb-6 bg-surface-variant pt-0"
                    >
                      <v-sheet class="ma-2 me-auto caption mx-0 grey--text "
                        >{{ $t("checkout.subTotal") }}:</v-sheet
                      >
                      <v-sheet class="ma-2 mx-4 font-weight-bold">
                        {{ chefTotal(chef) }}
                        {{ $t("common.currency") }}</v-sheet
                      >
                    </v-sheet>
                  </v-container>
                </v-card>
                <v-card class="elevation-0" tile v-if="checkout.length === 0">
                  <v-row class="mt-8" justify="center" align="center" row>
                    <v-card-title class="mt-12">
                      {{ $t("error.checkout") }}
                    </v-card-title>
                  </v-row>
                  <v-row class="my-4" justify="center" row>
                    <v-btn
                      :to="{ name: 'dishes' }"
                      class="my-6 font-weight-bold"
                      x-large
                      elevation="1"
                      text
                      outlined
                      color="primary"
                    >
                      {{ $t("common.add") }}
                      {{ $t("appHeader.tabs.dishes") }}
                      <v-icon size="20" class="mx-2" color="primary"
                        >fas fa-utensils</v-icon
                      >
                    </v-btn>
                  </v-row>
                </v-card>
              </v-card>
              <v-card
                v-if="checkout.length !== 0"
                outlined
                tile
                width="auto"
                height="auto"
              >
                <v-sheet class="d-flex bg-surface-variant">
                  <v-sheet class="ma-2 me-auto subtitle-2"
                    >{{ $t("checkout.total") }}:</v-sheet
                  >
                  <v-spacer></v-spacer>
                  <v-sheet class="ma-2 mx-4 font-weight-bold">
                    {{ chefsTotal() }}
                    {{ $t("common.currency") }}</v-sheet
                  >
                </v-sheet>
              </v-card>
              <v-card-actions
                v-if="checkout.length !== 0"
                class="justify-center ma-0 pa-0"
              >
                <v-btn
                  tile
                  solo
                  :block="$vuetify.breakpoint.mdAndUp ? true : true"
                  color="primary"
                  class="font-weight-bold white--text mx-0"
                  x-large
                  @click="selectedStep = 2"
                >
                  {{ $t("common.next") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content class="mt-0 pt-0 px-0 pb-0" step="2">
              <v-card
                tile
                class="px-1 elevation-0"
                min-height="360px"
                height="auto"
              >
                <v-card-subtitle
                  v-if="
                    checkout.length === 0 ||
                      filterRequiredMaterials().length === 0
                  "
                  class="ma-0 pa-0 text-center"
                  >{{ $t("checkout.steps.things") }}

                  <v-card-subtitle
                    class="ma-0 pa-0 text-center mt-12 caption"
                    >{{ $t("common.noResult") }}</v-card-subtitle
                  >
                </v-card-subtitle>
                <v-row
                  v-if="
                    dishes.length > 0 && filterRequiredMaterials().length > 0
                  "
                  class="mx-lg-12 text-center mx-1"
                  justify="center"
                  align="center"
                  row
                >
                  <v-col cols="12">
                    <v-card-subtitle class="ma-0 pa-0 ">{{
                      $t("checkout.steps.things")
                    }}</v-card-subtitle>

                    <v-chip-group :column="true" mandatory>
                      <v-chip
                        class="px-1"
                        readonly
                        label
                        outlined
                        small
                        v-for="(requiredMaterial,
                        index) in filterRequiredMaterials()"
                        :key="index"
                      >
                        <v-avatar class="primary white--text px-0" rounded left>
                          {{ requiredMaterial.quantity }}
                        </v-avatar>
                        <b>
                          {{ getMaterialsText(requiredMaterial) }}
                        </b>
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                v-if="checkout.length !== 0"
                outlined
                tile
                width="auto"
                height="auto"
              >
                <v-sheet class="d-flex bg-surface-variant">
                  <v-sheet class="ma-2 me-auto subtitle-2"
                    >{{ $t("checkout.total") }}:</v-sheet
                  >
                  <v-spacer></v-spacer>
                  <v-sheet class="ma-2 mx-4 font-weight-bold">
                    {{ chefsTotal() }}
                    {{ $t("common.currency") }}</v-sheet
                  >
                </v-sheet>
              </v-card>
              <v-card-actions
                v-if="checkout.length !== 0"
                class="justify-center ma-0 pa-0"
              >
                <v-row>
                  <v-col class="ma-0 pa-0" cols="6">
                    <v-btn
                      height="50"
                      outlined
                      tile
                      solo
                      block
                      color="primary"
                      class="font-weight-bold white--text mx-0"
                      @click="selectedStep = 1"
                    >
                      {{ $t("common.back") }}
                    </v-btn></v-col
                  >
                  <v-col class="ma-0 pa-0" cols="6">
                    <v-btn
                      height="50"
                      tile
                      solo
                      block
                      color="primary"
                      class="font-weight-bold white--text mx-0"
                      @click="selectedStep = 3"
                    >
                      {{ $t("common.next") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content class="mt-0 pt-0 px-0 pb-0" step="3">
              <v-card
                tile
                class="px-1 elevation-0"
                min-height="360px"
                height="auto"
              >
                <v-container class="px-lg-12">
                  <v-row justify="center">
                    <v-col cols="12" lg="8" xl="8">
                      <v-dialog
                        ref="dateDialog"
                        v-model="dateDialog"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :disabled="loading"
                            class="mt-2"
                            v-model="date"
                            readonly
                            :label="$t('common.date')"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            prepend-icon="fi fi-rr-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :min="new Date().toISOString().substring(0, 10)"
                          :max="
                            new Date(new Date().getTime() + 86400000 * 30)
                              .toISOString()
                              .substring(0, 10)
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="gray" @click="dateDialog = false">
                            {{ $t("common.cancel") }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dateDialog.save(date)"
                          >
                            {{ $t("common.ok") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>

                      <v-row>
                        <v-col
                          :class="
                            $route.params.lang === 'en'
                              ? 'mx-0 py-0 px-0 ml-3'
                              : 'mx-0 py-0 px-0 mr-3'
                          "
                          cols="4"
                        >
                          <v-select
                            class="px-0"
                            prepend-icon="fi fi-rr-clock"
                            :disabled="loading"
                            v-model="h"
                            dense
                            outlined
                            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            :label="$t('time.hour')"
                          ></v-select
                        ></v-col>
                        <v-col class="mx-1 px-0 py-0" cols="4">
                          <v-select
                            :disabled="loading"
                            v-model="m"
                            dense
                            outlined
                            :items="['00', '10', '20', '30', '40', '50']"
                            :label="$t('time.minute')"
                          ></v-select
                        ></v-col>
                        <v-col class="mx-0 px-0 py-0" cols="3">
                          <v-select
                            hide
                            class="px-0 mx-0"
                            :disabled="loading"
                            v-model="pm"
                            dense
                            outlined
                            :items="[$t('time.pm'), $t('time.am')]"
                          ></v-select
                        ></v-col>
                      </v-row>

                      <Where :loading="loading" />

                      <v-textarea
                        v-model="notes"
                        :placeholder="$t('common.notes')"
                        rows="1"
                        maxLength="200"
                        outlined
                        auto-grow
                        dense
                        prepend-icon="fi fi-rr-edit"
                        class="elevation-0"
                      ></v-textarea>

                      <v-row>
                        <v-col cols="6" class="py-1">
                          <v-text-field
                            v-model="voucher"
                            :placeholder="$t('common.voucher')"
                            maxLength="6"
                            outlined
                            dense
                            prepend-icon="fi fi-rr-gift-card"
                            class="elevation-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-card
                v-if="checkout.length !== 0"
                outlined
                tile
                width="auto"
                height="auto"
              >
                <v-sheet class="d-flex bg-surface-variant">
                  <v-sheet class="ma-2 me-auto subtitle-2"
                    >{{ $t("checkout.total") }}:</v-sheet
                  >
                  <v-spacer></v-spacer>
                  <v-sheet class="ma-2 mx-4 font-weight-bold">
                    {{ chefsTotal() }}
                    {{ $t("common.currency") }}</v-sheet
                  >
                </v-sheet>
              </v-card>
              <v-card-actions
                v-if="checkout.length !== 0"
                class="justify-center ma-0 pa-0"
              >
                <v-btn
                  tile
                  solo
                  :loading="loading"
                  :block="$vuetify.breakpoint.mdAndUp ? true : true"
                  color="primary"
                  class="font-weight-bold white--text mx-0"
                  x-large
                  @click="order"
                >
                  {{ $t("checkout.order") }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
    <ConfirmDialog
      @canceled="isConfirmDialogOpen = false"
      @confirmed="removeDishCheckout(confirmDialogDishId)"
      :isOpen="isConfirmDialogOpen"
      :text="confirmDialogText"
    />
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import DishService from "@/services/api/DishService";
import OrderService from "@/services/api/OrderService";
import UserService from "@/services/api/UserService";
import { mapMutations } from "vuex";
import axios from "../../src/shared/axios";
import ChefService from "@/services/api/ChefService";
import ConfirmDialog from "@/components/ConfirmDialog";
import Where from "@/components/Where";

export default {
  name: "Checkout",
  components: { ConfirmDialog, Where },
  data() {
    return {
      selectedStep: 1,
      total: 0,
      dishes: [],
      dateDialog: false,
      timeDialog: false,
      isSelectLocationPopupOpen: false,
      selectedLocation: null,
      loading: false,
      dishLoading: false,
      show: false,
      chefsName: [],
      confirmDialogText: "",
      confirmDialogDishId: "",
      isConfirmDialogOpen: false,
      h: null,
      m: null,
      pm: this.$t("time.pm"),
      voucher: ""
    };
  },
  computed: {
    ...mapFields("main", [
      "notes",
      "profileDrawer",
      "when.date",
      "when.time",
      "where.geocode",
      "where.location",
      "where"
    ]),
    ...mapFields("auth", ["token"]),
    ...mapFields("carts", ["checkout"]),
    ...mapFields("main", ["materials"]),
    multipleChefs() {
      return [...new Set(this.dishes.map(dish => dish.chef))].length;
    },
    chefs() {
      return [...new Set(this.dishes.map(dish => dish.chef))];
    }
  },
  watch: {
    checkout(val) {
      val ? this.getTotal() : "";
      if (val.length > 0) {
        this.dishesByIds();
      } else {
        this.dishes = [];
        this.total = 0;
      }
    }
  },
  methods: {
    ...mapMutations("carts", [
      "clearCheckout",
      "setSelectedNumberOfPersons",
      "removeDishFromCheckout"
    ]),
    chefTotal(chef) {
      var subTotal = 0;
      this.dishes.map(dish =>
        dish.chef === chef
          ? (subTotal += dish.price * dish.selectedNumberOfPersons)
          : ""
      );
      return subTotal;
    },
    chefsTotal() {
      var total = 0;
      this.dishes.map(
        dish => (total += dish.price * dish.selectedNumberOfPersons)
      );
      return total;
    },
    ConfirmRemoveDishCheckout(name, id) {
      this.confirmDialogText = this.$t("common.remove") + name;
      this.confirmDialogDishId = id;
      this.isConfirmDialogOpen = true;
    },
    removeDishCheckout(id) {
      this.token ? this.removeCheckoutDish(id) : "";
      this.removeDishFromCheckout(id);
      this.isConfirmDialogOpen = false;
      this.confirmDialogDishId = "";
    },
    async removeCheckoutDish(id) {
      const formData = new FormData();
      formData.append("dishId", id);
      try {
        await UserService.removeCheckoutDish(formData);
      } catch {}
    },
    changePersons(id, quantity, add) {
      this.setSelectedNumberOfPersons({
        id: id,
        quantity: add ? quantity + 1 : quantity - 1
      });
      const itemIndex = this.dishes.findIndex(x => x.id === id);
      this.dishes[itemIndex].selectedNumberOfPersons = add
        ? quantity + 1
        : quantity - 1;
      this.dishes = [...this.dishes];
    },
    async getChefNameById(id) {
      try {
        const name = await ChefService.getChefNameById(id);
        return name;
      } catch {}
    },
    prepTime(time) {
      return `${time >= 60 ? (time / 60).toFixed(1) : time} ${
        time >= 60
          ? time === 60
            ? this.$t("time.hour")
            : this.$t("time.hours")
          : this.$t("time.minute")
      }`;
    },
    dishImageUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    },
    filterRequiredMaterials() {
      let requiredMaterials = [].concat.apply(
        [],
        this.dishes.map(dish => dish.requiredMaterials)
      );
      let holder = {};

      requiredMaterials.forEach(material => {
        if (Object.prototype.hasOwnProperty.call(holder, material.value)) {
          holder[material.value] = holder[material.value] + material.quantity;
        } else {
          holder[material.value] = material.quantity;
        }
      });

      let filterRequiredMaterials = [];

      for (let prop in holder) {
        filterRequiredMaterials.push({ value: prop, quantity: holder[prop] });
      }

      filterRequiredMaterials = filterRequiredMaterials.filter(mat => {
        if (this.getMaterialsText(mat) !== undefined) return mat;
      });

      return filterRequiredMaterials;
    },
    setLocation({ latitude, longitude }) {
      this.selectedLocation = { latitude, longitude };
      this.location = this.selectedLocation;
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/geocode/${this.selectedLocation.latitude},${this.selectedLocation.longitude}`
        )
        .then(response => {
          this.geocode = response.data.geocode;
        });
    },
    getTotal() {
      this.total = 0;
      this.dishes.map(
        dish => (this.total += dish.price * dish.selectedNumberOfPersons)
      );
    },
    async order() {
      if (
        this.dishes.length > 0 &&
        this.h &&
        this.m &&
        this.date &&
        this.geocode
      ) {
        const time =
          this.pm === "PM" || this.pm === "م"
            ? this.h === 12
              ? `${this.h}:${this.m}`
              : `${this.h + 12}:${this.m}`
            : this.h === 12
            ? `00:${this.m}`
            : `${this.h < 10 ? `0${this.h}` : this.h}:${this.m}`;

        if (this.token) {
          const formData = new FormData();
          formData.append(
            "dishes",
            JSON.stringify(
              this.dishes.map(dish => {
                return {
                  id: dish.id,
                  selectedNumberOfPersons: dish.selectedNumberOfPersons,
                  price: dish.price,
                  chef: dish.chef
                };
              })
            )
          );
          formData.append("date", this.date);
          formData.append("time", time);
          formData.append(
            "notes",
            this.notes.length > 0 || this.voucher.length > 0
              ? this.voucher.length > 0
                ? `${this.notes} | ${this.voucher}`
                : this.notes
              : ""
          );
          formData.append("location", JSON.stringify(this.where));

          try {
            this.loading = true;
            await OrderService.order(formData);
            this.clearCheckout();
            this.date = "";
            this.time = "";
            this.geocode = "";
            this.location = null;
            this.notes = "";
            this.voucher = "";
            this.$root.$alert.success({
              message: this.$t("success.order")
            });
            this.$router.push({ name: "orders" });
          } catch {
          } finally {
            this.loading = false;
          }
        } else {
          this.$root.$alert.error({
            title: this.$t("error.book")
          });
          this.profileDrawer = true;
        }
      } else {
        this.$root.$alert.error({
          title:
            this.dishes.length === 0
              ? this.$t("error.checkout")
              : !this.h && !this.m && !this.date && !this.geocode
              ? this.$t("error.dateAndLocation")
              : !this.h
              ? this.$t("error.time")
              : !this.m
              ? this.$t("error.time")
              : !this.date
              ? this.$t("error.date")
              : !this.geocode
              ? this.$t("error.where")
              : ""
        });
      }
    },
    async dishesByIds() {
      const formData = new FormData();
      formData.append(
        "ids",
        JSON.stringify(this.checkout.map(dish => dish.id))
      );

      try {
        this.dishLoading = true;
        const { dishes } = await DishService.getDishesByIds(formData);
        this.dishes = dishes;

        this.dishes.map(dish => {
          this.checkout.map(item => {
            if (item.id === dish.id)
              dish.selectedNumberOfPersons = item.selectedNumberOfPersons;
          });
        });
        this.getTotal();

        const chefs = [...new Set(this.dishes.map(dish => dish.chef))];
        for (var i = 0; i < chefs.length; i++) {
          this.chefsName.push((await this.getChefNameById(chefs[i])) || "");
        }
      } catch {
      } finally {
        this.dishLoading = false;
      }
    },
    getMaterialsText(item) {
      return this.materials
        .filter(material => material.value === item.value)
        .map(material => material.text)[0];
    }
  },
  mounted() {
    this.dishesByIds();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>
