<template>
  <v-container class="mb-12">
    <v-card-text class="text-center mb-4">
      <h1 class="mb-3">{{ $t("common.privacy") }}</h1>
      <v-btn color="primary" v-if="panel.length === 0" @click="all">
        {{ $t("common.openAll") }}
      </v-btn>
      <v-btn color="primary" outlined v-else @click="none">
        {{ $t("common.closeAll") }}
      </v-btn>
    </v-card-text>
    <p>
      {{ $t("common.lastUpdated") }} <b>{{ updatedAt }}</b>
    </p>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(item, i) in items" :key="i">
        <v-expansion-panel-header
          ><b>{{ item.title }}</b>
        </v-expansion-panel-header>
        <div v-if="panel.length > 0">
          <v-expansion-panel-content
            :key="index"
            v-for="(paragraph, index) in nextLineParagraph(item.text)"
          >
            {{ paragraph }}
          </v-expansion-panel-content>
          <br />
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from "../../src/shared/axios";
export default {
  name: "Privacy",
  data() {
    return {
      panel: [],
      items: this.$t("lists.privacy"),
      updatedAt: "2023-06-27"
    };
  },
  methods: {
    all() {
      this.panel = this.items.map((k, i) => i);
    },
    none() {
      this.panel = [];
    },
    nextLineParagraph(text) {
      return text.split(". ");
    }
  },
  created() {
    window.scrollTo({ top: 0 });
    this.all();
    if (sessionStorage.getItem("p") === null)
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/p`)
        .then(() => {
          sessionStorage.setItem("p", true);
        })
        .catch();
  }
};
</script>
