<template>
  <div>
    <SearchDiv />
    <Values />
    <JoinUs />
    <FandQ />
  </div>
</template>
<script>
import SearchDiv from "../components/home/SearchDiv.vue";
import Values from "../components/home/Values.vue";
import JoinUs from "../components/home/JoinUs.vue";
import FandQ from "../components/home/FandQ.vue";

export default {
  name: "Home",
  components: { SearchDiv, Values, JoinUs, FandQ }
};
</script>
