import Vuex from "vuex";
import Vue from "vue";
import VuexPersist from "vuex-persist";
import main from "./modules/main";
import carts from "./modules/carts";
import auth from "./modules/auth";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "CHEFLK",
  storage: window.localStorage,
  reducer: state => ({
    carts: {
      wishlist: {
        chefs: state.carts.wishlist.chefs,
        dishes: state.carts.wishlist.dishes
      },
      checkout: state.carts.checkout
    }
  })
});

export default new Vuex.Store({
  strict: true,
  plugins: [vuexPersist.plugin],
  modules: {
    main: main,
    carts: carts,
    auth: auth
  }
});
