import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../plugins/i18n";
import Chefs from "../views/Chefs.vue";
import Dishes from "../views/Dishes.vue";
import Chef from "../views/Chef.vue";
import Dish from "../views/Dish.vue";
import Wishlist from "../views/Wishlist.vue";
import Checkout from "../views/Checkout.vue";
import TermsAndConditions from "../views/TermsAndConditions";
import Orders from "../views/Orders";
import Privacy from "../views/Privacy";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: `${i18n.locale}/`
    },
    {
      path: "/chefs",
      redirect: `${i18n.locale}/chefs`
    },
    {
      path: "/chefs/:chef",
      redirect: `${i18n.locale}/chefs/:chef`
    },
    {
      path: "/dishes/:dish",
      redirect: `${i18n.locale}/dishes/:dish`
    },
    {
      path: "/dishes",
      redirect: `${i18n.locale}/dishes`
    },
    {
      path: "/wishlist",
      redirect: `${i18n.locale}/wishlist`
    },
    {
      path: "/checkout",
      redirect: `${i18n.locale}/checkout`
    },
    {
      path: "/terms-and-conditions",
      redirect: `${i18n.locale}/terms-and-conditions`
    },
    {
      path: "/privacy",
      redirect: `${i18n.locale}/privacy`
    },
    {
      path: "/orders",
      redirect: `${i18n.locale}/orders`
    },
    {
      path: "*",
      redirect: `${i18n.locale}/`
    },
    {
      path: "/:lang",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "/",
          name: "home",
          component: Home
        },
        {
          path: "chefs",
          name: "chefs",
          component: Chefs
        },
        {
          path: "chefs/:chef",
          name: "chef",
          component: Chef
        },
        {
          path: "dishes",
          name: "dishes",
          component: Dishes
        },

        {
          path: "dishes/:dish",
          name: "dish",
          component: Dish
        },
        {
          path: "wishlist",
          name: "wishlist",
          component: Wishlist
        },
        {
          path: "checkout",
          name: "checkout",
          component: Checkout
        },
        {
          path: "terms-and-conditions",
          name: "terms-and-conditions",
          component: TermsAndConditions
        },
        {
          path: "privacy",
          name: "privacy",
          component: Privacy
        },
        {
          path: "orders",
          name: "orders",
          component: Orders
        },
        {
          path: "orders/:order",
          component: Orders
        }
      ]
    }
  ]
});

export default router;
