<template>
  <v-container>
    <v-tabs color="red darken-4" class="mt-2 elevation-1" centered grow>
      <v-tab @click="type = 'dish'">
        <v-card-title class="font-weight-bold"
          >{{ $t("appHeader.tabs.dishes") }}
          <v-chip
            v-if="wishlist.dishes.length > 0"
            color="primary"
            class="mx-2"
            small
            >{{
              this.wishlist.dishes.length > 0 ? this.wishlist.dishes.length : ""
            }}</v-chip
          >
        </v-card-title></v-tab
      >
      <v-tab @click="type = 'chef'">
        <v-card-title class="font-weight-bold"
          >{{ $t("appHeader.tabs.chefs") }}
          <v-chip
            v-if="wishlist.chefs.length > 0"
            color="primary"
            class="mx-2"
            small
            >{{
              this.wishlist.chefs.length > 0 ? this.wishlist.chefs.length : ""
            }}</v-chip
          >
        </v-card-title>
      </v-tab>
    </v-tabs>
    <CardsList
      :loading="loading"
      :type="type"
      :withHeader="false"
      :items="itemsHandler"
      :itemsPerPage="itemsPerPage"
      @update-items-per-page="updateItemsPerPage"
    />
  </v-container>
</template>

<script>
import CardsList from "@/components/CardsList.vue";
import { mapFields } from "vuex-map-fields";
import DishService from "@/services/api/DishService";
import ChefService from "@/services/api/ChefService";
import { mapMutations } from "vuex";

export default {
  name: "Wishlist",
  components: { CardsList },
  data() {
    return {
      type: "dish",
      dishes: [],
      chefs: [],
      loading: false,
      itemsPerPage: 8
    };
  },
  computed: {
    ...mapFields("carts", ["wishlist"]),
    itemsHandler() {
      return this.type === "dish" ? this.dishes : this.chefs;
    }
  },
  methods: {
    ...mapMutations("carts", ["setWishlistDishes"]),
    async dishesByIds() {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.wishlist.dishes));

      try {
        this.loading = true;
        const { dishes } = await DishService.getDishesByIds(formData);
        this.dishes = dishes;
        this.dishes.length === this.wishlist.dishes.length
          ? ""
          : this.setWishlistDishes(this.dishes.map(dish => dish.id));
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async chefsByIds() {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(this.wishlist.chefs));

      try {
        this.loading = true;
        const { chefs } = await ChefService.getChefsByIds(formData);
        this.chefs = chefs;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    }
  },
  watch: {
    type(val) {
      val === "dish" ? this.dishesByIds() : this.chefsByIds();
    },
    wishlist: {
      deep: true,
      handler(val) {
        if (val.chefs) {
          this.wishlist.chefs.length > 0
            ? this.chefsByIds()
            : (this.chefs = []);
        }
        if (val.dishes) {
          this.wishlist.dishes.length > 0
            ? this.dishesByIds()
            : (this.dishes = []);
        }
      }
    }
  },
  mounted() {
    this.type === "dish"
      ? this.wishlist.dishes.length > 0
        ? this.dishesByIds()
        : ""
      : this.wishlist.chefs.length > 0
      ? this.chefsByIds()
      : "";
  }
};
</script>
