<template>
  <v-container class="text-center pt-0 mb-12 pb-8">
    <v-row class="mt-0" justify="center" align="center" row>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-scroll-x-transition>
            <v-card
              class="text-center"
              height="250"
              width="100%"
              :elevation="hover ? 12 : 6"
              tile
            >
              <v-img height="100%" :src="require('@/assets/value1.webp')">
                <v-row class="my-12" justify="center">
                  <h1 class="my-12 white--text font-weight-bold display-1">
                    {{ $t("home.values.luxury") }}
                  </h1>
                </v-row>
              </v-img>
            </v-card>
          </v-scroll-x-transition>
        </v-hover>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-scroll-x-transition>
            <v-card tile height="250" width="100%" :elevation="hover ? 12 : 6">
              <v-img
                class="pt-12"
                height="100%"
                :src="require('@/assets/value2.webp')"
              >
                <v-row class="pt-12" justify="center">
                  <h1 style="color: white;" class="font-weight-bold display-1">
                    {{ $t("home.values.foru") }}
                  </h1>
                </v-row>
              </v-img>
            </v-card>
          </v-scroll-x-transition>
        </v-hover>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-scroll-x-transition>
            <v-card tile height="250" width="100%" :elevation="hover ? 12 : 6">
              <v-img height="100%" :src="require('@/assets/value3.webp')">
                <v-row class="my-12" justify="center">
                  <h1
                    style="color: white;"
                    class="my-12 font-weight-bold display-1"
                  >
                    {{ $t("home.values.premise") }}
                  </h1>
                </v-row>
              </v-img>
            </v-card>
          </v-scroll-x-transition>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Values"
};
</script>
<style scoped>
.chef {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  color: primary;
}
.display-1 {
  text-shadow: 1px 1px black;
  padding-top: 0%;
}
</style>
