import RequestService from "../utils/RequestService";

export default {
  updateChefProfile(payload) {
    return RequestService({
      method: "put",
      url: "/chefs/profile",
      data: payload
    });
  },
  getChefProfile(id) {
    return RequestService({
      method: "get",
      url: `/chefs/profile/${id}`
    });
  },
  getChefDishImages() {
    return RequestService({
      method: "get",
      url: `/chefs/profile/images/dishes`
    });
  },
  uploadChefImage(payload) {
    return RequestService({
      method: "post",
      url: "/chefs/image",
      data: payload
    });
  },
  removeChefImage(payload) {
    return RequestService({
      method: "post",
      url: "/chefs/image/remove",
      data: payload
    });
  },
  getChefs() {
    return RequestService({
      method: "get",
      url: "/chefs"
    });
  },
  getChefById(id) {
    return RequestService({
      method: "get",
      url: `/chefs/${id}`
    });
  },
  getChefsByIds(payload) {
    return RequestService({
      method: "post",
      url: "/chefs/ids",
      data: payload
    });
  },
  getChefNameById(id) {
    return RequestService({
      method: "get",
      url: `/chefs/${id}/name`
    });
  }
};
