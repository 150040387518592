<template>
  <v-container class="mt-10">
    <v-row align="center" justify="center" row>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-img
          :height="$vuetify.breakpoint.mdAndUp ? 550 : 300"
          :src="require('@/assets/becomeChef.webp')"
        >
        </v-img>
      </v-col>
      <v-col class="text-center" cols="12" xl="8" lg="8" md="8">
        <h1 class="my-lg-12 my-5 font-weight-bold text-capitalize">
          {{ $t("common.become") }}
          <h1 class="chef">{{ $t("common.chef") }}</h1>
          {{ $t("common.show") }}
        </h1>
        <v-btn
          @click="$root.$chefSignUpForm.open()"
          class="white--text"
          color="primary"
          x-large
          >{{ $t("common.join") }}</v-btn
        >
      </v-col>
    </v-row></v-container
  >
</template>
<script>
export default {
  name: "JoinUs"
};
</script>
<style scoped>
.chef {
  color: #7f0000;
}
</style>
