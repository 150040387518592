import RequestService from "../utils/RequestService";

export default {
  order(payload) {
    return RequestService({
      method: "post",
      url: "/orders",
      data: payload
    });
  },
  accept(payload) {
    return RequestService({
      method: "put",
      url: "/orders/accept",
      data: payload
    });
  },
  reject(payload) {
    return RequestService({
      method: "put",
      url: "/orders/reject",
      data: payload
    });
  },
  getOrders(payload) {
    return RequestService({
      method: "get",
      url: "/orders",
      data: payload
    });
  },
  pay(payload) {
    return RequestService({
      method: "post",
      url: "/orders/pay",
      data: payload
    });
  },
  delivered(payload) {
    return RequestService({
      method: "post",
      url: "/orders/delivered",
      data: payload
    });
  },
  rate(payload) {
    return RequestService({
      method: "post",
      url: "/orders/rate",
      data: payload
    });
  },
  getUserMobile(orderId) {
    return RequestService({
      method: "get",
      url: `/orders/user/mobile/${orderId}`
    });
  }
};
