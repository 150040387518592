var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-1 mx-auto text-center overflow-hidden",attrs:{"loading":_vm.loading,"elevation":_vm.hover ? 12 : 3,"height":_vm.expandCard
      ? _vm.$vuetify.breakpoint.mdAndUp
        ? 480
        : 'auto'
      : _vm.$route.name === 'checkout'
      ? 335
      : 319,"max-width":_vm.expandCard ? 'auto' : 320}},[(_vm.id === _vm.dish.chef && _vm.dish.imgs && _vm.dish.imgs.length === 0)?_c('v-btn',{staticClass:"d-flex d-flex-block",attrs:{"icon":""},on:{"click":function($event){_vm.isEditDishPopupOpen = true}}},[_c('v-icon',{attrs:{"color":"info","medium":""}},[_vm._v("fas fa-edit")])],1):_vm._e(),_c('v-row',{attrs:{"row":"","justify":_vm.expandCard ? 'space-around' : 'center'}},[_c('v-col',{staticClass:"mt-0 pt-0 pb-0",attrs:{"cols":_vm.expandCard && _vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('v-carousel',{attrs:{"hide-delimiters":_vm.dish.imgs ? _vm.dish.imgs.length < 2 : '',"show-arrows":_vm.dish.imgs ? _vm.dish.imgs.length > 1 : '',"hide-delimiter-background":!_vm.hover,"show-arrows-on-hover":"","height":_vm.expandCard
            ? _vm.$vuetify.breakpoint.mdAndUp
              ? _vm.dish.isMenu
                ? 460
                : 480
              : 280
            : _vm.$route.name === 'checkout'
            ? 185
            : 210}},[(_vm.dish.imgs && _vm.dish.imgs.length === 0)?_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("dish.dishForm.uploadImage"))+" ")]):_vm._e(),_vm._l((_vm.dish.imgs),function(img,index){return _c('v-carousel-item',{key:index,attrs:{"src":_vm.imgUrl(img)}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"row":""}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"1"}},[(_vm.role === 'CHEF' && _vm.token && _vm.id === _vm.$route.params.chef)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isEditDishPopupOpen = true}}},[_c('v-icon',{attrs:{"color":"info","medium":""}},[_vm._v("fas fa-edit")])],1):_vm._e(),(_vm.role !== 'CHEF')?_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"icon":""}},[(_vm.onWishlist)?_c('v-icon',{attrs:{"color":"primary","medium":""},on:{"click":_vm.removeWishlist}},[_vm._v("fas fa-star")]):_c('v-icon',{staticClass:"starIcon",attrs:{"medium":""},on:{"click":_vm.addWishlist}},[_vm._v("far fa-star")])],1):_vm._e()],1),_c('v-spacer',{staticClass:"mx-5",style:(_vm.$route.name !== 'dish' ? 'cursor: pointer;' : ''),on:{"click":_vm.dishView}}),_c('v-col',{staticClass:"pa-0 my-0 mx-0",attrs:{"cols":"2"}},[(_vm.role === 'CHEF' && _vm.token && _vm.id === _vm.$route.params.chef)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.ConfirmRemoveDishCheckout(_vm.dish.name)}}},[_vm._v("fas fa-trash ")])],1):_vm._e(),(_vm.$route.name === 'checkout')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.ConfirmRemoveDishCheckout(_vm.dish.name)}}},[_c('v-icon',{attrs:{"color":"primary","medium":""}},[_vm._v("fas fa-trash")])],1):_vm._e()],1),_c('ConfirmDialog',{attrs:{"isOpen":_vm.isConfirmDialogOpen,"text":_vm.confirmDialogText},on:{"canceled":function($event){_vm.isConfirmDialogOpen = false},"confirmed":function($event){_vm.role === 'CHEF' && _vm.token && _vm.id === _vm.$route.params.chef
                  ? _vm.removeDish(_vm.dish.id)
                  : _vm.removeDishCheckout(_vm.dish.id)}}}),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.fileDeletingDialog),callback:function ($$v) {_vm.fileDeletingDialog=$$v},expression:"fileDeletingDialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pt-2 pb-2"},[_vm._v(" "+_vm._s(_vm.fileDeletingDialog ? _vm.$t("common.deleting") : "")+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-1 mb-0",staticStyle:{"opacity":"0"},attrs:{"width":"20em","height":_vm.dish.imgs.length > 1 ? '7.5em' : '10em'},on:{"click":_vm.dishView}})],1)],1),_c('v-row',{staticClass:"text-end mt-0",style:(_vm.$route.name !== 'dish' ? 'cursor: pointer;' : ''),on:{"click":_vm.dishView}},[_c('v-spacer',{staticClass:"mx-5",style:(_vm.$route.name !== 'dish' ? 'cursor: pointer;' : ''),on:{"click":_vm.dishView}})],1)],1)})],2),(_vm.dish.isMenu)?_c('v-card',{staticClass:"font-weight-bold white--text py-0 caption",attrs:{"tile":"","color":"primary","width":"100%"}},[_vm._v(_vm._s(_vm.$t("dish.menu")))]):_vm._e()],1),_c('v-col',{class:_vm.expandCard ? '' : 'mt-1 pt-0 pb-0',style:(!_vm.expandCard ? 'cursor: pointer;' : ''),attrs:{"cols":_vm.expandCard && _vm.$vuetify.breakpoint.mdAndUp ? 6 : 12},on:{"click":_vm.dishView}},[_c('v-card-title',{class:_vm.expandCard
            ? 'pb-2 pt-0 justify-center'
            : 'pb-2 pt-0 justify-center d.flex d-block mx-0'},[_vm._v(" "+_vm._s(_vm.$route.name === "dish" && !_vm.expandCard ? _vm.dish.name.substr(0, 17) : _vm.dish.name)+" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandCard || _vm.$route.name === 'checkout'),expression:"expandCard || $route.name === 'checkout'"}],attrs:{"align":"center"}},[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandCard),expression:"expandCard"}],attrs:{"color":"gray lighten-2","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("fi fi-rr-cross-small")])],1)],1)],1),_c('v-card-subtitle',{class:_vm.expandCard ? ' py-0 text-left' : 'py-0 center'},[_c('v-row',{class:_vm.expandCard ? ' px-2 mt-2' : _vm.dish.isMenu ? 'mt-2' : 'mb-1 mt-3',attrs:{"align":_vm.expandCard ? 'start' : 'center',"justify":_vm.expandCard ? 'start' : 'center'}},[_c('v-rating',{attrs:{"value":_vm.dish.stars,"color":"amber","dense":"","half-increments":"","readonly":"","size":_vm.expandCard ? 28 : 26,"background-color":"grey lighten-2"}})],1),(_vm.expandCard)?_c('v-card-title',{staticClass:"ma-0 pa-0 subtitle-1"},[_vm._v(" "+_vm._s(_vm.dish.price)+" "+_vm._s(_vm.$t("common.currency"))+" "),_c('span',{staticClass:"caption mx-1 grey--text"},[_vm._v(" "+_vm._s(_vm.$t("common.per"))+" ")])]):_c('div',{staticClass:"ma-0 pa-0 subtitle-1"},[_vm._v(" "+_vm._s(_vm.dish.price)+" "+_vm._s(_vm.$t("common.currency"))+" ")]),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$route.name !== 'checkout' &&
              _vm.expandCard &&
              _vm.$route.name !== 'dishes'
          ),expression:"\n            $route.name !== 'checkout' &&\n              expandCard &&\n              $route.name !== 'dishes'\n          "}],class:_vm.expandCard
              ? 'ma-0 pa-0 subtitle-2 mt-1 text-start'
              : 'ma-0 pa-0 subtitle-2 mt-1 justify-center',staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.dish.description)+" ")]),(
            _vm.expandCard &&
              _vm.dish.alergies &&
              _vm.$route.name !== 'checkout' &&
              _vm.dish.alergies.length > 0
          )?_c('v-card-title',{staticClass:"orange--text text--lighten-2 ma-0 pa-0",staticStyle:{"font-size":"0.65em"}},[_c('v-icon',{staticClass:"mb-0",attrs:{"size":"8","color":"orange lighten-3"}},[_vm._v("fas fa-exclamation-triangle")]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.$t("dish.allergies"))+" ")]),_vm._l((_vm.dish.alergies),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(_vm.getAlergiesText(item))+" "),(index + 1 < _vm.dish.alergies.length)?_c('span',[_vm._v(", ")]):_vm._e()])})],2):_vm._e()],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandCard),expression:"expandCard"}],staticClass:"mt-lg-3 mt-5 mt-lg-12 pt-lg-12"},[_c('v-row',{attrs:{"row":"","justify":"center","align":"center"}},[_c('v-col',{staticClass:"px-0 mb-3",attrs:{"cols":"10"}},[_c('v-icon',{attrs:{"disabled":_vm.selectedNumberOfPersons <= _vm.dish.minNumberOfPersons
                    ? true
                    : false,"color":"primary","medium":""},on:{"click":function($event){_vm.selectedNumberOfPersons--}}},[_vm._v(" far fa-minus-square ")]),_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.selectedNumberOfPersons)+" "+_vm._s(_vm.selectedNumberOfPersons === 1 ? _vm.$t("common.person") : _vm.$t("common.persons"))+" ")]),_c('v-icon',{attrs:{"disabled":_vm.selectedNumberOfPersons >= _vm.dish.maxNumberOfPersons
                    ? true
                    : false,"color":"primary","medium":""},on:{"click":function($event){_vm.selectedNumberOfPersons++}}},[_vm._v(" far fa-plus-square ")])],1)],1),_c('v-row',{staticClass:"mt-lg-0",attrs:{"justify":"center","align":"end","row":""}},[_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandCard && _vm.role !== 'CHEF' ? true : false),expression:"expandCard && role !== 'CHEF' ? true : false"}],staticClass:"my-0 py-0 px-0 mt-lg-8",attrs:{"justify":"center","align":"center"}},[(!_vm.isDishModified)?_c('v-btn',{attrs:{"color":"black","outlined":""},on:{"click":_vm.addAndPushToCheckout}},[_vm._v(" "+_vm._s(_vm.$t("checkout.order"))+" ")]):_vm._e(),(!_vm.isDishModified && !_vm.selectedNumberOfPersonsFromCheckout)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.addCheckout}},[_vm._v(" "+_vm._s(_vm.$t("common.addCart"))+" ")]):_vm._e(),(_vm.isDishModified)?_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":_vm.addAndPushToCheckout}},[_vm._v(" "+_vm._s(_vm.$t("common.modify"))+" ")]):_vm._e()],1)],1)],1)])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"row":""}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[(_vm.$route.name === 'checkout')?_c('v-card-actions',{staticClass:"mt-0 pt-0"},[_c('v-btn',{staticClass:"pa-0 ma-0 font-weight-bold",attrs:{"block":"","color":"blue","text":""},on:{"click":_vm.dishView}},[_vm._v(" "+_vm._s(_vm.$t("common.modify"))+" ")])],1):_vm._e()],1)],1),_c('DishForm',{attrs:{"editDish":_vm.dish.id,"isOpen":_vm.isEditDishPopupOpen},on:{"close-popup":function($event){_vm.isEditDishPopupOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }