<template>
  <v-container class="mt-8">
    <v-row justify="center" align="center" row>
      <v-col cols="12" md="6" lg="8" xl="8">
        <v-card-title class="title-1 justify-center my-2 font-weight-bold">{{
          $t("home.frequntlyAskedQuestions")
        }}</v-card-title>
        <v-expansion-panels popout class="my-2">
          <v-expansion-panel v-for="(item, i) in $t('home.fandQ')" :key="i">
            <v-expansion-panel-header>
              {{ item.q }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.a }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FandQ"
};
</script>
