<template>
  <Dialog
    :value="isOpen"
    maxWidth="800"
    :title="
      editDish ? $t('dish.dishForm.editDish') : $t('dish.dishForm.addDish')
    "
    :rightBtnText="
      editDish ? $t('dish.dishForm.editDish') : $t('dish.dishForm.addDish')
    "
    @right-btn-click="editDish ? dishModify() : dishUplaod()"
    :leftBtnText="$t('common.cancel')"
    @left-btn-click="clearForm"
    :persistent="true"
    :color="editDish ? 'info' : 'primary'"
    :loading="loading"
  >
    <template>
      <v-container>
        <form ref="dishForm" class="mx-lg-12">
          <ImageCropper
            ref="cropper"
            :value="isImageCropperDialogOpen"
            :image="image"
            @upload-image="uploadImage"
            @cancel-crop="resetImageAndCloseCropPopUp"
            :type="'TMP'"
          />
          <v-btn
            :disabled="dish.images && dish.images.length === 5"
            class="white--text text-none mb-4"
            color="black"
            width="100%"
            :class="{ 'mobile-button': $vuetify.breakpoint.smAndDown }"
            @click="openUploadDialog()"
          >
            <v-icon small class="mr-1">fas fa-plus</v-icon>
            {{ $t("dish.dishForm.uploadImage") }}
            {{
              dish.images && dish.images.length === 5
                ? $t("error.maxImages")
                : ""
            }}
          </v-btn>

          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="fileSelected"
            accept="image/jpeg, image/png"
          />
          <v-dialog v-model="fileUploadingDialog" persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-2 pb-2">
                {{
                  fileDeletingDialog && fileUploadingDialog
                    ? "Deleteing"
                    : $t("common.loading")
                }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-carousel
            v-if="dish.images.length"
            :hide-delimiters="dish.images.length < 2"
            :show-arrows="dish.images.length > 1"
            show-arrows-on-hover
            height="200"
            class="mb-4"
          >
            <v-carousel-item
              contain
              v-for="(img, index) in dish.images"
              :key="index"
              :src="imgUrl(img)"
            >
              <v-container class="ma-0 pa-0" fluid>
                <v-row
                  justify="center"
                  align="center"
                  row
                  class="mt-0 pt-0 mx-0"
                >
                  <v-col cols="1" class="mx-0 px-0">
                    <v-btn
                      :disabled="dish.images && dish.images.length === 5"
                      @click="openImageCropperByIcon(img)"
                      class="d-flex d-flex-block"
                      color="#E0E0E0"
                      icon
                    >
                      <v-icon color="info" medium>fas fa-crop</v-icon>
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1" class="mx-lg-1 mx-5">
                    <v-btn color="#E0E0E0" icon>
                      <v-icon
                        @click="confirmRemoveDishImage()"
                        color="primary"
                        medium
                        >fas fa-trash</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <ConfirmDialog
                @canceled="isConfirmDialogOpen = false"
                @confirmed="removeDishImage(img)"
                :isOpen="isConfirmDialogOpen"
                :text="confirmDialogText"
              />
            </v-carousel-item>
          </v-carousel>

          <div class="d-flex flex-inline">
            <v-checkbox v-model="dish.isMenu"> </v-checkbox>
            <v-card-subtitle class="mt-1">
              {{ this.$t("dish.dishForm.isMenu") }}
            </v-card-subtitle>
          </div>
          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="dish.ar.name"
            :error-messages="nameErrorsAR"
            :counter="20"
            maxLength="20"
            :label="$t('dish.dishForm.nameAR')"
            required
            @input="$v.dish.ar.name.$touch()"
            @keypress="onlyAllowed"
          ></v-text-field>
          <v-textarea
            :loading="loading"
            rows="1"
            outlined
            dense
            v-model="dish.ar.description"
            :error-messages="descriptionErrorsAR"
            :counter="120"
            maxLength="120"
            :label="$t('dish.dishForm.descriptionAR')"
            @input="$v.dish.ar.description.$touch()"
          ></v-textarea>

          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="dish.en.name"
            :error-messages="nameErrorsEN"
            :counter="20"
            maxLength="20"
            :label="$t('dish.dishForm.nameEN')"
            required
            @input="$v.dish.en.name.$touch()"
            @keypress="onlyAllowed"
          ></v-text-field>
          <v-textarea
            :loading="loading"
            rows="1"
            outlined
            dense
            v-model="dish.en.description"
            :error-messages="descriptionErrorsEN"
            :counter="120"
            maxLength="120"
            :label="$t('dish.dishForm.descriptionEN')"
            @input="$v.dish.en.description.$touch()"
          ></v-textarea>

          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="dish.price"
            :error-messages="priceErrors"
            maxLength="4"
            :label="$t('dish.dishForm.price')"
            required
            @input="$v.dish.price.$touch()"
            @keypress="onlyNumbers"
          ></v-text-field>
          <v-row row justify="center" align="center" class="mb-0 pb-0">
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-text-field
                outlined
                maxLength="3"
                dense
                v-model="dish.minNumberOfPersons"
                :error-messages="minNumberOfPersonsErrors"
                :label="$t('dish.dishForm.minNumberOfPersons')"
                required
                @input="$v.dish.minNumberOfPersons.$touch()"
                @keypress="onlyNumbers"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6">
              <v-text-field
                maxLength="3"
                outlined
                dense
                v-model="dish.maxNumberOfPersons"
                :error-messages="maxNumberOfPersonsErrors"
                :label="$t('dish.dishForm.maxNumberOfPersons')"
                required
                @input="$v.dish.maxNumberOfPersons.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row row justify="center" align="center" class="my-0 py-0">
            <v-col cols="12" xl="8" lg="8">
              <v-text-field
                dense
                readonly
                class="my-0 centered-input"
                v-model="dish.prepTime"
                :error-messages="prepTimeErrors"
                :label="$t('dish.dishForm.prepTime')"
              >
                <v-icon
                  @click="dish.prepTime += 5"
                  :disabled="dish.prepTime >= 240 ? true : false"
                  slot="append"
                  color="primary"
                >
                  fas fa-plus
                </v-icon>
                <v-icon
                  @click="dish.prepTime -= 5"
                  :disabled="dish.prepTime <= 0 ? true : false"
                  slot="prepend"
                  color="primary"
                >
                  fas fa-minus
                </v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-select
            v-model="dish.alergies"
            :items="alergies"
            multiple
            solo
            dense
            :label="$t('dish.dishForm.alergies')"
          >
          </v-select>
          <v-autocomplete
            v-model="dish.categories"
            :items="categories"
            :loading="categoriesLoading"
            multiple
            solo
            dense
            required
            :error-messages="categoriesErrors"
            :label="$t('dish.dishForm.categories')"
          >
          </v-autocomplete>
          <v-autocomplete
            v-model="dish.requiredMaterials"
            :items="materials"
            solo
            multiple
            return-object
            dense
            clearable
            :label="$t('dish.dishForm.requiredMaterials')"
          >
            <template v-slot:selection="data">
              <v-chip
                class="ma-1"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar
                  class="primary white--text"
                  left
                  v-text="data.item.quantity"
                ></v-avatar>

                {{ data.item.text }}
              </v-chip>
            </template>

            <template v-slot:item="{ item, attrs, on }">
              <template v-if="typeof item !== 'object'">
                <v-list-item-content v-text="item"></v-list-item-content>
              </template>
              <template class="mx-lg-12" v-else>
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content class="mx-lg-12" @click.stop>
                    <v-text-field
                      class="centered-input mx-lg-12"
                      :label="item.text"
                      dense
                      readonly
                      v-model="item.quantity"
                    >
                      <v-icon
                        @click="updateMaterial(item, 'increase')"
                        :disabled="item.quantity >= 100 ? true : false"
                        slot="append"
                        color="primary"
                      >
                        fas fa-plus
                      </v-icon>
                      <v-icon
                        @click="updateMaterial(item, 'decrease')"
                        :disabled="item.quantity <= 1 ? true : false"
                        slot="prepend"
                        color="primary"
                      >
                        fas fa-minus
                      </v-icon>
                    </v-text-field>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>
        </form>
      </v-container>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/Dialog";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import DishService from "@/services/api/DishService";
import ImageCropper from "@/components/ImageCropper";
import { mapFields } from "vuex-map-fields";
import ChefService from "@/services/api/ChefService";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "DishForm",
  mixins: [validationMixin],
  components: {
    Dialog,
    ImageCropper,
    ConfirmDialog
  },
  props: {
    isOpen: Boolean,
    editDish: { type: String, default: "" }
  },
  data() {
    return {
      isConfirmDialogOpen: false,
      confirmDialogText: "",
      loading: false,
      fileUploadingDialog: false,
      fileDeletingDialog: false,

      cropUploadedImage: false,
      isImageCropperDialogOpen: false,
      categoriesLoading: false,
      image: "",
      quantity: 1,
      alergies: this.$t("lists.alergies"),

      dish: {
        en: {
          name: "",
          description: ""
        },
        ar: {
          name: "",
          description: ""
        },
        price: null,
        minNumberOfPersons: null,
        maxNumberOfPersons: null,
        prepTime: null,
        categories: [],
        alergies: [],
        requiredMaterials: [],
        images: [],
        isMenu: false,
        chef: null
      }
    };
  },
  computed: {
    ...mapFields("auth", ["id", "status"]),
    ...mapFields("main", ["categories", "materials"]),
    nameErrorsEN() {
      const errors = [];
      if (!this.$v.dish.en.name.$dirty) return errors;
      !this.$v.dish.en.name.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.dish.en.name.nameValid &&
        errors.push(
          this.$t("error.isRequired") + " " + this.$t("dish.dishForm.nameEN")
        );
      return errors;
    },
    descriptionErrorsEN() {
      const errors = [];
      if (!this.$v.dish.en.description.$dirty) return errors;
      !this.$v.dish.en.description.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    nameErrorsAR() {
      const errors = [];
      if (!this.$v.dish.ar.name.$dirty) return errors;
      !this.$v.dish.ar.name.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.dish.ar.name.nameValid &&
        errors.push(
          this.$t("error.isRequired") + " " + this.$t("dish.dishForm.nameAR")
        );
      return errors;
    },
    descriptionErrorsAR() {
      const errors = [];
      if (!this.$v.dish.ar.description.$dirty) return errors;
      !this.$v.dish.ar.description.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.dish.price.$dirty) return errors;
      !this.$v.dish.price.required && errors.push(this.$t("error.isRequired"));
      !this.$v.dish.price.numeric && errors.push(this.$t("error.number"));
      !this.$v.dish.price.moreThanZero &&
        errors.push(this.$t("error.moreThanZero"));
      return errors;
    },
    minNumberOfPersonsErrors() {
      const errors = [];
      if (!this.$v.dish.minNumberOfPersons.$dirty) return errors;
      !this.$v.dish.minNumberOfPersons.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.dish.minNumberOfPersons.numeric &&
        errors.push(this.$t("error.number"));
      !this.$v.dish.minNumberOfPersons.moreThanZero &&
        errors.push(this.$t("error.moreThanZero"));
      !this.$v.dish.minNumberOfPersons.MinMoreThanMax &&
        errors.push(this.$t("error.minNumber"));
      return errors;
    },
    maxNumberOfPersonsErrors() {
      const errors = [];
      if (!this.$v.dish.maxNumberOfPersons.$dirty) return errors;
      !this.$v.dish.maxNumberOfPersons.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.dish.maxNumberOfPersons.numeric &&
        errors.push(this.$t("error.number"));
      !this.$v.dish.maxNumberOfPersons.moreThanZero &&
        errors.push(this.$t("error.moreThanZero"));
      !this.$v.dish.maxNumberOfPersons.MaxLessThanMin &&
        errors.push(this.$t("error.minNumber"));
      return errors;
    },
    prepTimeErrors() {
      const errors = [];
      if (!this.$v.dish.prepTime.$dirty) return errors;
      !this.$v.dish.prepTime.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    categoriesErrors() {
      const errors = [];
      if (!this.$v.dish.categories.$dirty) return errors;
      !this.$v.dish.categories.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    requiredMaterialsErrors() {
      const errors = [];
      if (!this.$v.dish.requiredMaterials.$dirty) return errors;
      !this.$v.dish.requiredMaterials.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    }
  },
  methods: {
    onlyNumbers($event) {
      if (!new RegExp("^[0-9]$").test(String.fromCharCode($event.which)))
        $event.preventDefault();
    },
    onlyAllowed($event) {
      if (
        !new RegExp("^[\u0621-\u064Aa-zA-Z ]$").test(
          String.fromCharCode($event.which)
        )
      ) {
        $event.preventDefault();
      }
    },
    updateMaterial(item, operation) {
      this.$store.commit("main/updateMaterialQuantity", {
        item,
        operation
      });
    },
    remove(item) {
      const index = this.dish.requiredMaterials.indexOf(item);
      if (index >= 0) this.dish.requiredMaterials.splice(index, 1);
    },
    imgUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    },
    confirmRemoveDishImage() {
      this.confirmDialogText = this.$t("chef.deleteChefDishImage");
      this.isConfirmDialogOpen = true;
    },
    async removeDishImage(id) {
      this.isConfirmDialogOpen = false;

      const formData = new FormData();
      formData.append("uuid", id);
      this.fileUploadingDialog = true;
      this.fileDeletingDialog = true;
      try {
        if (!this.editDish) await DishService.removeDishImage(formData);
        else {
          formData.append("dishId", this.dish.id);
          await DishService.removeEditDishImage(formData);
        }
        this.dish.images = this.dish.images.filter(item => item !== id);
        this.$root.$alert.success({
          message: this.$t("success.dishImageDeleted")
        });
      } catch {
      } finally {
        this.isConfirmDialogOpen = false;
        this.fileUploadingDialog = false;
        this.fileDeletingDialog = false;
      }
    },
    async getUploadedImages() {
      this.editDish ? (this.loading = true) : "";
      try {
        const chefProfile = await ChefService.getChefDishImages();
        this.dish.images = chefProfile;
      } catch {
      } finally {
        this.editDish ? (this.loading = false) : "";
      }
    },
    async dishUplaod() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.dish.images.length > 0) {
        const formData = new FormData();
        formData.append("nameEN", this.dish.en.name);
        formData.append("nameAR", this.dish.ar.name);
        formData.append("descriptionEN", this.dish.en.description);
        formData.append("descriptionAR", this.dish.ar.description);
        formData.append("price", this.dish.price);
        formData.append("minNumberOfPersons", this.dish.minNumberOfPersons);
        formData.append("maxNumberOfPersons", this.dish.maxNumberOfPersons);
        formData.append("prepTime", this.dish.prepTime);
        formData.append("categories", JSON.stringify(this.dish.categories));
        formData.append("alergies", JSON.stringify(this.dish.alergies));
        formData.append(
          "requiredMaterials",
          JSON.stringify(
            this.dish.requiredMaterials.map(material => ({
              value: material.value,
              quantity: material.quantity
            }))
          )
        );
        formData.append("images", JSON.stringify(this.dish.images));
        formData.append("isMenu", this.dish.isMenu);

        this.loading = true;
        try {
          await DishService.uploadDish(formData);
          this.$root.$alert.success({
            message: this.$t("success.dish")
          });
          this.$router.go(0);
        } catch (err) {
        } finally {
          this.loading = false;
        }
      } else {
        this.dish.images.length === 0
          ? this.$root.$alert.error({
              message: this.$t("error.dishImage")
            })
          : "";
        this.$refs.dishForm.scrollIntoView({ behavior: "smooth" });
      }
    },
    async dishModify() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.dish.images.length > 0) {
        const formData = new FormData();
        formData.append("dishId", this.dish.id);
        formData.append("nameEN", this.dish.en.name);
        formData.append("nameAR", this.dish.ar.name);
        formData.append("descriptionEN", this.dish.en.description);
        formData.append("descriptionAR", this.dish.ar.description);
        formData.append("price", this.dish.price);
        formData.append("minNumberOfPersons", this.dish.minNumberOfPersons);
        formData.append("maxNumberOfPersons", this.dish.maxNumberOfPersons);
        formData.append("prepTime", this.dish.prepTime);
        formData.append("categories", JSON.stringify(this.dish.categories));
        formData.append("alergies", JSON.stringify(this.dish.alergies));
        formData.append(
          "requiredMaterials",
          JSON.stringify(
            this.dish.requiredMaterials.map(material => ({
              value: material.value,
              quantity: material.quantity
            }))
          )
        );
        formData.append("images", JSON.stringify(this.dish.images));
        formData.append("isMenu", this.dish.isMenu);

        this.loading = true;
        try {
          await DishService.modifyDish(formData);
          this.$root.$alert.success({
            message: this.$t("success.dishModified")
          });
          this.$emit("close-popup");
          setInterval(() => {
            this.$router.go(0);
          }, 2000);
        } catch {
        } finally {
          this.loading = false;
          this.clearForm();
        }
      } else {
        this.dish.images.length === 0
          ? this.$root.$alert.error({
              message: this.$t("error.dishImage")
            })
          : "";
        this.$refs.dishForm.scrollIntoView({ behavior: "smooth" });
      }
    },
    async getEditDishById(id) {
      this.loading = true;
      try {
        const dish = await DishService.getEditDishById(id);
        this.dish = dish;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    openImageCropperByIcon(image) {
      this.cropUploadedImage = true;
      this.image = this.imgUrl(image);
      this.isImageCropperDialogOpen = true;

      if (this.$refs.cropper) {
        this.$refs.cropper.replace(this.image);
      }
    },
    addDishImage(image) {
      this.dish.images.unshift(image.type);
    },
    clearForm() {
      this.$v.$reset();
      this.editDish
        ? this.dish.images.length > 0
          ? this.$emit("close-popup")
          : this.$root.$alert.error({
              message: this.$t("error.dishImage")
            })
        : this.$emit("close-popup");
    },
    async uploadImage(formData) {
      this.fileUploadingDialog = true;
      try {
        let file;
        if (!this.editDish) {
          if (this.cropUploadedImage) {
            this.removeDishImage(this.image.split("/")[4]);
          }
          file = await DishService.uploadImage(formData);
        } else {
          formData.append("dishId", this.dish.id);
          file = await DishService.uploadEditImage(formData);
        }
        this.addDishImage(file);
        this.resetImageAndCloseCropPopUp();
      } catch {
      } finally {
        this.isImageCropperDialogOpen = false;
        this.fileUploadingDialog = false;
        this.cropUploadedImage = false;
      }
    },
    fileSelected() {
      if (this.$refs.fileInput.files[0].size < 1000000) {
        const reader = new FileReader();
        reader.readAsDataURL(this.$refs.fileInput.files[0]);
        reader.onload = async () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            if (image.width < 500 || image.height < 500) {
              this.$root.$alert.error({
                title: `${this.$t("error.dimensions")} ${image.width} x ${
                  image.height
                } ${this.$t("error.dimensionsMustBe")}`
              });
            } else {
              this.image = reader.result;
              this.openCropPopUp();

              if (this.$refs.cropper) {
                this.$refs.cropper.replace(this.image);
              }
            }
          };
        };
      } else {
        this.$root.$alert.error({
          message: this.$t("error.imageSize")
        });
      }
    },
    openUploadDialog() {
      this.$refs.fileInput.click();
    },
    openCropPopUp() {
      this.isImageCropperDialogOpen = true;
    },
    resetImageAndCloseCropPopUp() {
      this.image = "";
      this.$refs.fileInput.value = "";
      this.$refs.cropper.reset();
      this.isImageCropperDialogOpen = false;
    }
  },
  watch: {
    isOpen(val) {
      this.categories = this.categories.filter(key => key.value !== "Menu");
      val
        ? !this.editDish
          ? this.getUploadedImages()
          : this.getEditDishById(this.editDish)
        : "";
    }
  },
  validations: {
    dish: {
      en: {
        name: {
          required,
          maxLength: maxLength(20),
          nameValid: function(value) {
            return /^[\u0621-\u064Aa-zA-Z ]{2,20}$/.test(value);
          }
        },
        description: {
          required,
          maxLength: maxLength(120)
        }
      },
      ar: {
        name: {
          required,
          maxLength: maxLength(20),
          nameValid: function(value) {
            return /^[\u0621-\u064Aa-zA-Z ]{2,20}$/.test(value);
          }
        },
        description: {
          required,
          maxLength: maxLength(120)
        }
      },
      price: {
        required,
        numeric,
        maxLength: maxLength(4),
        moreThanZero: function(value) {
          return value > 0 && !/^0/.test(value);
        }
      },
      minNumberOfPersons: {
        required,
        numeric,
        maxLength: maxLength(3),
        moreThanZero: function(value) {
          return value > 0 && !/^0/.test(value);
        },
        MinMoreThanMax(value) {
          return parseInt(value) <= parseInt(this.dish.maxNumberOfPersons);
        }
      },
      maxNumberOfPersons: {
        required,
        numeric,
        maxLength: maxLength(3),
        moreThanZero: function(value) {
          return value > 0 && !/^0/.test(value);
        },
        MaxLessThanMin(value) {
          return parseInt(value) >= parseInt(this.dish.minNumberOfPersons);
        }
      },
      prepTime: { required, maxLength: maxLength(4) },
      categories: { required }
    }
  }
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
  font-size: 1.15em;
}
</style>
