<template>
  <v-app>
    <Alert />
    <AppHeader />
    <Profile />
    <OtpDialog />
    <v-main v-scroll="onScroll">
      <v-scroll-x-transition :hide-on-leave="true">
        <router-view :key="$route.fullPath"></router-view>
      </v-scroll-x-transition>
    </v-main>
    <ChefSignUpForm />
    <AppFooter />
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import Profile from "./components/Profile.vue";
import ChefSignUpForm from "./components/ChefSignUpForm.vue";
import Alert from "./components/Alert.vue";
import OtpDialog from "./components/OtpDialog";
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";
import axios from "../src/shared/axios";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    Alert,
    Profile,
    ChefSignUpForm,
    OtpDialog
  },
  data() {
    return {
      scrolled: false
    };
  },
  computed: {
    ...mapFields("main", ["scroll", "categories", "materials", "error"]),
    ...mapFields("auth", ["expiresIn"])
  },
  watch: {
    error() {
      if (this.error) {
        this.$root.$alert.error({
          message: this.error.message
        });
        this.$store.dispatch("main/setError", null);
      }
    },
    scrolled(val) {
      if (val === true && sessionStorage.getItem("v") === null)
        axios
          .get(`${process.env.VUE_APP_BACKEND_URL}/v`)
          .then(() => {
            sessionStorage.setItem("v", true);
          })
          .catch();
    }
  },
  methods: {
    getCategories() {
      this.categories = this.$t("lists.categories");
    },
    getMaterials() {
      this.materials = this.$t("lists.materials");

      this.materials = this.materials.map(item => ({
        value: item.value,
        text: item.text,
        category: item.category,
        quantity: 1
      }));

      const categories = this.materials
        .map((thing, index, self) => {
          if (index === self.findIndex(t => t.category === thing.category)) {
            const item = { index: index, category: thing.category };
            return item;
          }
        })
        .filter(item => item != undefined);

      this.addMaterialsHeaders(categories);
    },
    ...mapMutations("auth", ["logout"]),
    ...mapMutations("main", ["addMaterialsHeaders"]),
    onScroll() {
      if (window.pageYOffset > 0) {
        this.scroll = true;
        this.scrolled = true;
      } else this.scroll = false;
    }
  },
  beforeCreate() {
    //RtL
    if (this.$route.params.lang === "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
  mounted() {
    this.getCategories();
    this.getMaterials();
    if (this.expiresIn) {
      const date = new Date();
      date.setSeconds(date.getSeconds()) > this.expiresIn ? this.logout() : "";
    }
  }
};
</script>
<style>
@font-face {
  font-family: "cheflk";
  font-display: swap;
  src: local("C Regular"), url("assets/fonts/C-Regular.woff");
}
</style>
