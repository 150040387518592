<template>
  <Dialog
    :value="isOpen"
    maxWidth="800"
    @input="setLocationAndClosePopup"
    :title="$t('home.searchDiv.locationPickerModalTitle')"
    :rightBtnText="$t('home.searchDiv.selectLocation')"
    @right-btn-click="setLocationAndClosePopup"
    :leftBtnText="$t('common.cancel')"
    @left-btn-click="$emit('close-popup')"
    :persistent="true"
  >
    <div>
      <v-btn
        @click="geolocation"
        :loading="loading"
        style="position: absolute; bottom: 16%; left:6%"
        color="primary"
        fixed
        bottom
        left
        elevation="2"
        fab
        raised
      >
        <v-icon color="white">fas fa-location-arrow</v-icon>
      </v-btn>
      <GmapMap
        class="map"
        :center="{
          lat: currentLocation.latitude,
          lng: currentLocation.longitude
        }"
        :options="{
          restriction: {
            latLngBounds: {
              north: 26,
              south: 24,
              west: 46,
              east: 47
            },
            strictBounds: true
          }
        }"
        :zoom="15"
        @click="changeCurrentLocation"
      >
        <GmapMarker
          :clickable="true"
          :draggable="true"
          @drag="changeCurrentLocation"
          :position="{
            lat: currentLocation.latitude,
            lng: currentLocation.longitude
          }"
        />
      </GmapMap>
    </div>

    <v-card-subtitle class="pa-0 red--text darken-4">{{
      currentLocationPermission ? $t("error.location") : ""
    }}</v-card-subtitle>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
import GmapMap from "vue2-google-maps/src/components/map";
import GmapMarker from "vue2-google-maps/src/components/marker";

const getFallbackcurrentLocation = () => ({
  latitude: 24.713241,
  longitude: 46.674931
});

export default {
  name: "SelectLocationPopup",
  components: {
    Dialog,
    GmapMarker,
    GmapMap
  },
  props: {
    selectedLocation: {
      type: Object,
      default: getFallbackcurrentLocation
    },
    isOpen: Boolean
  },
  watch: {
    isOpen() {
      if (this.isOpen && this.selectedLocation) {
        this.setMarkerAndcurrentLocation(this.selectedLocation);
      }
      if (this.isOpen && !this.selectedLocation) this.geolocation();
    }
  },
  data: () => ({
    currentLocation: getFallbackcurrentLocation(),
    markerLocation: getFallbackcurrentLocation(),
    currentLocationPermission: false,
    loading: false
  }),
  methods: {
    resetLocationAndClosePopup() {
      this.$emit("close-popup");
      this.setMarkerAndcurrentLocation();
    },
    setMarkerAndcurrentLocation(location) {
      this.markerLocation = location;
      this.currentLocation = location;
    },
    changeCurrentLocation({ latLng }) {
      this.currentLocation = {
        latitude: latLng.lat(),
        longitude: latLng.lng()
      };
    },
    setLocationAndClosePopup() {
      this.$emit("setLocation", this.currentLocation);
      this.$emit("close-popup");
    },
    geolocation() {
      this.loading = true;
      navigator.geolocation.getCurrentPosition(
        position => {
          this.currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          this.loading = false;
        },
        error => {
          if (error) this.currentLocationPermission = true;
          this.loading = false;
        }
      );
    }
  },
  mounted() {
    this.isOpen ? this.geolocation() : "";
  }
};
</script>
<style scoped>
.map {
  width: 100%;
  height: 35em;
}
</style>
