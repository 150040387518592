<template>
  <div>
    <v-text-field
      readonly
      v-model="geocode"
      :label="$t('common.location')"
      :outlined="true"
      :dense="true"
      @click.stop="isSelectLocationPopupOpen = true"
      color="primary"
      :disabled="loading"
      prepend-icon="fi fi-rr-marker"
    >
    </v-text-field>
    <SelectLocationPopup
      :isOpen="isSelectLocationPopupOpen"
      @close-popup="isSelectLocationPopupOpen = false"
      @setLocation="setLocation"
      :selectedLocation="selectedLocation"
    />
  </div>
</template>

<script>
import SelectLocationPopup from "@/components/SelectLocationPopup";
import { mapFields } from "vuex-map-fields";
import axios from "../shared/axios";

export default {
  name: "Where",
  components: { SelectLocationPopup },
  props: { loading: { type: Boolean, default: false } },
  data() {
    return {
      isSelectLocationPopupOpen: false,
      selectedLocation: null
    };
  },
  computed: {
    ...mapFields("main", ["where.location", "where.geocode"])
  },
  methods: {
    setLocation({ latitude, longitude }) {
      this.selectedLocation = { latitude, longitude };
      this.location = this.selectedLocation;
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/geocode/${this.selectedLocation.latitude},${this.selectedLocation.longitude}`
        )
        .then(response => {
          this.geocode = response.data.geocode;
        })
        .catch(err => {
          this.$root.$alert.error({
            message: err.response.data.message
          });
        });
    }
  }
};
</script>
