import RequestService from "../utils/RequestService";

export default {
  uploadImage(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/image",
      data: payload
    });
  },
  uploadEditImage(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/image/edit",
      data: payload
    });
  },
  uploadDish(payload) {
    return RequestService({
      method: "post",
      url: "/dishes",
      data: payload
    });
  },
  modifyDish(payload) {
    return RequestService({
      method: "put",
      url: "/dishes",
      data: payload
    });
  },
  getDishes(payload) {
    return RequestService({
      method: "get",
      url: "/dishes",
      data: payload
    });
  },
  getDishesByIds(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/ids",
      data: payload
    });
  },
  getDishesByCategories(categories) {
    return RequestService({
      method: "get",
      url: `/dishes/category/${categories}`
    });
  },
  getDishById(id) {
    return RequestService({
      method: "get",
      url: `/dishes/${id}`
    });
  },
  getEditDishById(id) {
    return RequestService({
      method: "get",
      url: `/dishes/${id}/edit`
    });
  },
  getDishesByChefId(id) {
    return RequestService({
      method: "get",
      url: `/dishes/chef/${id}`
    });
  },
  getChefDishes() {
    return RequestService({
      method: "get",
      url: "/dishes/chef/dishes"
    });
  },
  removeDishImage(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/image/remove",
      data: payload
    });
  },
  removeEditDishImage(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/edit/image/remove",
      data: payload
    });
  },
  removeDish(payload) {
    return RequestService({
      method: "post",
      url: "/dishes/remove",
      data: payload
    });
  }
};
