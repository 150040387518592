<template>
  <Dialog
    :value="isOtpPopUpOpen"
    @input="$emit('input', $event)"
    :title="$t('common.securityCheck')"
    :text="$t('common.enterSecurityCode')"
    @left-btn-click="clearOtpValues"
    @right-btn-click="verifyOtpChallenge"
    :loading="submitting"
    :rightBtnText="$t('common.verify')"
    :persistent="true"
  >
    <v-form>
      <v-text-field
        outlined
        class="mt-6"
        :label="$t('common.securityCode')"
        v-model="otpChallenge"
        :hint="$t('common.otpHint')"
        persistent-hint
        maxLength="4"
        counter="4"
        type="text"
        pattern="\d*"
        :error-messages="otpChallengeErrors"
        @change="$v.otpChallenge.$touch()"
        @blur="$v.otpChallenge.$touch()"
        @keypress="onlyNumbers"
        required
      ></v-text-field>
      <span v-if="countDown !== 0">
        {{ $t("common.expire") }} {{ countDown }} {{ $t("time.seconds") }}
      </span>
      <span class="black--text" v-else>
        {{ $t("common.expired") }}
      </span>
    </v-form>
  </Dialog>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Dialog from "./Dialog";
import AuthService from "@/services/api/AuthService";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";

export default {
  mixins: [validationMixin],
  components: {
    Dialog
  },
  data() {
    return {
      submitting: false,
      countDown: 120
    };
  },
  computed: {
    ...mapFields("auth", [
      "isOtpPopUpOpen",
      "otpChallenge",
      "isOtpSuccessful",
      "mobile",
      "role",
      "forgotPassword",
      "token"
    ]),
    ...mapFields("main", ["profileDrawer"]),
    otpChallengeErrors() {
      const errors = [];
      if (!this.$v.otpChallenge.$dirty) return errors;
      !this.$v.otpChallenge.required &&
        errors.push(
          this.$t("common.securityCode") + " " + this.$t("error.isRequired")
        );
      return errors;
    }
  },
  methods: {
    ...mapMutations("auth", ["logout"]),
    onlyNumbers($event) {
      if (!new RegExp("^[0-9]$").test(String.fromCharCode($event.which))) {
        $event.preventDefault();
      }
    },
    async verifyOtpChallenge() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData();
        formData.append("mobile", this.mobile);
        formData.append("otp", this.otpChallenge);
        formData.append("role", this.role);

        try {
          this.submitting = true;

          if (!this.forgotPassword) {
            if (this.token) {
              formData.delete("mobile");
              formData.delete("role");
              await AuthService.verifyOtpRmAccount(formData);
              this.$root.$alert.success({
                message: this.$t("success.accountDeleted")
              });
              this.logout();
            } else {
              await AuthService.verifyOtpChallenge(formData);
              this.$root.$alert.success({
                message: this.$t("success.verified")
              });
            }
          } else {
            await AuthService.verifyOtpResetPassword(formData);
            this.$root.$alert.success({
              message: this.$t("success.passwordReset")
            });
            this.forgotPassword = false;
          }

          this.isOtpSuccessful = true;
          this.otpChallenge = "";
          this.isOtpPopUpOpen = false;

          this.profileDrawer = true;
          this.countDown = 120;
        } catch (err) {
          if (err.response.status === 401) {
            this.isOtpSuccessful = false;

            this.$root.$alert.error({
              message: this.$t("error.otp")
            });
          }
        } finally {
          this.submitting = false;
        }
      }
    },
    async getOtpChallenge() {
      const mobileFormData = new FormData();
      mobileFormData.append("mobile", this.mobile);
      mobileFormData.append("role", this.role);

      try {
        if (this.token) await AuthService.getOtpChallengeLoggedIn();
        else await AuthService.getOtpChallenge(mobileFormData);
      } catch {
        this.isOtpPopUpOpen = false;
      }
    },
    clearOtpValues() {
      this.countDown = 120;
      this.isOtpSuccessful = false;
      this.otpChallenge = "";
      this.isOtpPopUpOpen = false;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.clearOtpValues();
      }
    }
  },
  watch: {
    isOtpPopUpOpen(val) {
      this.countDown = 120;
      val ? this.countDownTimer() : "";
      val && (this.mobile || this.token) ? this.getOtpChallenge() : "";
    }
  },
  validations: {
    otpChallenge: { required, maxLength: maxLength(4), minLength: minLength(4) }
  },
  updated() {
    if (!this.isOtpPopUpOpen && this.isOtpSuccessful) {
      this.isOtpSuccessful = false;
    }
  }
};
</script>
