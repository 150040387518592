<template>
  <v-navigation-drawer
    :right="$i18n.locale === 'en' ? false : true"
    v-model="profileDrawer"
    absolute
    temporary
  >
    <v-list-item>
      <v-list-item-avatar v-if="role === 'USER'">
        <v-icon v-if="!token" small>fi fi-rr-user</v-icon>
        <v-icon v-else small color="primary">fi fi-rr-user</v-icon>
      </v-list-item-avatar>
      <v-list-item-avatar v-if="role === 'CHEF'">
        <v-img
          contain
          :height="25"
          width="25"
          :src="require('../../public/img/icons/favicon-32x32.png')"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-if="!token">
          <span v-if="role === 'CHEF'">
            {{ $t("common.chef") }}
          </span>
          {{ $t("common.login") }}
        </v-list-item-title>
        <v-list-item-title v-else>{{
          resetPassword ? $t("profile.resetPassword") : $t("common.welcome")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-tabs :dark="role === 'CHEF' ? true : false" v-if="!token" grow>
      <v-tab active-class="font-weight-bold" @click="role = 'USER'">{{
        $t("common.user")
      }}</v-tab>
      <v-tab active-class="font-weight-bold" @click="chefTab">
        {{ $t("common.im") }} {{ $t("common.chef") }}</v-tab
      >
    </v-tabs>

    <v-divider></v-divider>
    <div v-if="!token || resetPassword" class="ma-5 mt-12 text-center">
      <div :style="!forgotPassword ? 'height: 12em;' : ''">
        <v-text-field
          class="mb-3"
          v-if="!resetPassword"
          :loading="uploading"
          outlined
          v-model="mobile"
          type="text"
          pattern="\d*"
          @change="$v.mobile.$touch()"
          @blur="$v.mobile.$touch()"
          @keypress="onlyNumbers"
          hint="966XXXXXXXXX"
          :error-messages="mobileErrors"
          :label="$t('common.mobile')"
          maxLength="12"
          required
        >
        </v-text-field>
        <v-text-field
          v-if="resetPassword"
          :loading="uploading"
          v-model="passwordCurrent"
          class="mb-0 pb-0"
          maxLength="24"
          outlined
          :label="$t('common.passwordCurrent')"
          type="password"
          :error-messages="passwordCurrentErrors"
          @change="$v.passwordCurrent.$touch()"
          @blur="$v.passwordCurrent.$touch()"
          :hint="$t('common.passwordHint')"
        >
        </v-text-field>
        <v-text-field
          v-if="!forgotPassword || resetPassword"
          :loading="uploading"
          v-model="password"
          class="mb-0 pb-0"
          maxLength="24"
          outlined
          :label="
            resetPassword ? $t('common.passwordNew') : $t('common.password')
          "
          type="password"
          :error-messages="passwordErrors"
          @change="$v.password.$touch()"
          @blur="$v.password.$touch()"
        >
        </v-text-field>
        <v-card-title class="text-caption justify-center mt-0 pt-0 mx-0">
          <u
            style="cursor: pointer;"
            @click="forgotPassword = true"
            v-if="!forgotPassword && !resetPassword && !newUser"
          >
            {{ $t("common.forgot") }}
          </u>
        </v-card-title>
        <v-text-field
          v-if="newUser || resetPassword"
          outlined
          :loading="uploading"
          :error-messages="passwordConfirmErrors"
          v-model="passwordConfirm"
          :label="
            resetPassword
              ? $t('common.passwordNewConfirm')
              : $t('common.passwordConfirm')
          "
          type="password"
          @change="$v.passwordConfirm.$touch()"
          @blur="$v.passwordConfirm.$touch()"
        ></v-text-field>
      </div>
      <v-spacer v-if="newUser || resetPassword" class="my-10"></v-spacer>

      <v-btn
        v-if="forgotPassword && !newUser"
        @click="getOtpChallenge"
        color="primary white--text"
      >
        {{ $t("common.forgot") }}
      </v-btn>

      <div v-if="!resetPassword">
        <div v-if="!forgotPassword">
          <v-row class="mb-0 mt-6 py-0" v-if="!newUser" justify="center">
            <v-btn
              block
              large
              v-if="!newUser"
              @click="login"
              color="primary white--text"
            >
              {{ $t("common.login") }}
            </v-btn>
          </v-row>

          <v-card-subtitle
            class="mt-1 mx-0"
            v-if="!newUser && role === 'USER'"
            @click="newUser = true"
          >
            {{ $t("common.new") }}
            <a
              ><u> {{ $t("common.signup") }} </u></a
            >
          </v-card-subtitle>
          <v-btn
            @click="$root.$chefSignUpForm.open()"
            elevation="1"
            class="mx-0 mt-8"
            outlined
            color="black"
            v-if="!newUser"
          >
            <v-img
              class="mx-0 px-0"
              contain
              :height="20"
              :src="require('../../public/img/icons/favicon-32x32.png')"
            />

            {{ $t("common.register") }}
          </v-btn>

          <v-btn text class="mx-1" @click="newUser = false" v-if="newUser">
            <u>
              {{ $t("common.cancel") }}
            </u>
          </v-btn>

          <v-btn
            class="my-12"
            v-if="newUser"
            @click="userSignUp"
            color="primary white--text"
          >
            {{ $t("common.signup") }}
          </v-btn>
        </div>
        <v-card-subtitle v-else>
          <a
            ><u @click="forgotPassword = false">
              {{ $t("common.cancel") }}
            </u></a
          >
        </v-card-subtitle>
      </div>
      <v-card-actions class="mt-8" v-else>
        <v-btn @click="resetPassword = false" text color="primary white--text">
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn
          class="my-12"
          @click="changePassword"
          color="primary white--text"
        >
          {{ $t("profile.reset") }}
        </v-btn>
      </v-card-actions>
    </div>
    <v-card flat v-else>
      <v-list>
        <v-list-item-group v-model="model">
          <v-list-item
            :to="{ name: 'chef', params: { chef: id } }"
            @click="refresh"
            v-if="role === 'CHEF'"
            exact
          >
            <v-list-item-icon class="mx-3">
              <v-icon
                :color="
                  $route.name === 'chef' && $route.params.chef === id
                    ? 'primary'
                    : ''
                "
                >fi fi-rr-user</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("profile.profile") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mt-1"
            v-for="(item, i) in items"
            :key="i"
            :to="{ name: item.to }"
          >
            <v-list-item-icon class="mx-3">
              <v-icon :color="$route.name === 'orders' ? 'primary' : ''"
                >fi fi-rr-concierge-bell</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="resetPassword = true" exact>
            <v-list-item-icon class="mx-3">
              <v-icon>fi fi-rr-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("profile.resetPassword")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-spacer></v-spacer>
      <v-list class="py-0 mt-1">
        <v-list-item-group>
          <v-list-item @click="logOut">
            <v-list-item-icon class="mx-3">
              <v-icon size="18">fas fa-door-open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-spacer></v-spacer>
      <v-list-item
        style="margin-top: 100%;"
        @click="isConfirmDialogOpen = true"
      >
        <v-list-item-icon class="mx-3"> </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            {{ $t("profile.delete") }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <ConfirmDialog
        @canceled="isConfirmDialogOpen = false"
        @confirmed="getOtpChallengeRmAccount()"
        :isOpen="isConfirmDialogOpen"
        :text="$t('common.remove') + $t('common.account')"
      />
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import AuthService from "@/services/api/AuthService";
import { mapMutations, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import ConfirmDialog from "@/components/ConfirmDialog";
import {
  required,
  minLength,
  maxLength,
  sameAs,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: "Profile",
  components: { ConfirmDialog },
  mixins: [validationMixin],
  data() {
    return {
      isConfirmDialogOpen: false,
      newUser: false,
      uploading: false,
      passwordCurrent: "",
      password: "",
      passwordConfirm: "",
      resetPassword: false,
      items: [
        {
          icon: "fas fa-concierge-bell",
          text: this.$t("profile.orders"),
          to: "orders"
        }
      ],
      model: null
    };
  },
  computed: {
    ...mapFields("main", ["profileDrawer", "error"]),
    ...mapFields("carts", ["wishlist", "checkout"]),
    ...mapFields("auth", [
      "token",
      "isOtpPopUpOpen",
      "mobile",
      "role",
      "id",
      "forgotPassword"
    ]),
    mobileErrors() {
      const errors = [];
      if (!this.$v.mobile.$dirty) return errors;
      !this.$v.mobile.required && errors.push(this.$t("error.isRequired"));
      !this.$v.mobile.numeric && errors.push(this.$t("error.number"));
      !this.$v.mobile.minLength && errors.push(this.$t("error.mobile"));
      !this.$v.mobile.mobileValid && errors.push(this.$t("error.mobile"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("error.isRequired"));
      !this.$v.password.minLength && errors.push(this.$t("error.minChar"));
      !this.$v.password.containsLowercase &&
        errors.push(this.$t("error.containsLowercase"));
      !this.$v.password.containsUppercase &&
        errors.push(this.$t("error.containsUppercase"));
      !this.$v.password.containsNumber &&
        errors.push(this.$t("error.containsNumber"));
      !this.$v.password.containsSpecial &&
        errors.push(this.$t("error.containsSpecial"));
      if (this.forgotPassword || this.newUser) {
        this.password !== this.passwordConfirm &&
          this.$v.password.$dirty &&
          this.$v.passwordConfirm.$dirty &&
          errors.push(this.$t("error.passwordShouldMatch"));
      }
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("error.isRequired"));
      !this.$v.password.minLength && errors.push(this.$t("error.minChar"));
      !this.$v.password.containsLowercase &&
        errors.push(this.$t("error.containsLowercase"));
      !this.$v.password.containsUppercase &&
        errors.push(this.$t("error.containsUppercase"));
      !this.$v.password.containsNumber &&
        errors.push(this.$t("error.containsNumber"));
      !this.$v.password.containsSpecial &&
        errors.push(this.$t("error.containsSpecial"));
      this.password !== this.passwordConfirm &&
        this.$v.password.$dirty &&
        this.$v.passwordConfirm.$dirty &&
        errors.push(this.$t("error.passwordShouldMatch"));
      return errors;
    },
    passwordCurrentErrors() {
      const errors = [];
      if (!this.$v.passwordCurrent.$dirty) return errors;
      !this.$v.passwordCurrent.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.passwordCurrent.minLength &&
        errors.push(this.$t("error.minChar"));
      !this.$v.passwordCurrent.containsLowercase &&
        errors.push(this.$t("error.containsLowercase"));
      !this.$v.passwordCurrent.containsUppercase &&
        errors.push(this.$t("error.containsUppercase"));
      !this.$v.passwordCurrent.containsNumber &&
        errors.push(this.$t("error.containsNumber"));
      !this.$v.passwordCurrent.containsSpecial &&
        errors.push(this.$t("error.containsSpecial"));
      this.passwordCurrent === this.password &&
        this.$v.passwordCurrent.$dirty &&
        errors.push(this.$t("error.passwordShouldntMatch"));
      return errors;
    }
  },
  methods: {
    onlyNumbers($event) {
      if (!new RegExp("^[0-9]$").test(String.fromCharCode($event.which)))
        $event.preventDefault();
    },
    chefTab() {
      this.role = "CHEF";
      this.newUser = false;
    },
    refresh() {
      window.location.reload();
    },
    ...mapMutations("auth", ["logout"]),
    ...mapMutations("carts", ["clearCart"]),
    ...mapActions("auth", ["setUser"]),
    async changePassword() {
      this.$v.$touch();
      if (
        !this.$v.passwordConfirm.$invalid &&
        !this.$v.password.$invalid &&
        !this.$v.passwordCurrent.$invalid &&
        this.passwordCurrent !== this.password
      ) {
        const formData = new FormData();
        formData.append("passwordCurrent", this.passwordCurrent);
        formData.append("password", this.password);
        formData.append("passwordConfirm", this.passwordConfirm);

        this.uploading = true;
        try {
          await AuthService.resetPassword(formData);
          this.resetPassword = false;
          this.passwordCurrent = "";
          this.password = "";
          this.passwordConfirm = "";

          this.$root.$alert.success({
            message: this.$t("success.reset")
          });
        } catch {
        } finally {
          this.uploading = false;
        }
      }
    },
    getOtpChallenge() {
      this.$v.$touch();
      if (!this.$v.mobile.$invalid) {
        this.forgotPassword = true;
        this.isOtpPopUpOpen = true;
      }
    },
    getOtpChallengeRmAccount() {
      this.isConfirmDialogOpen = false;
      this.isOtpPopUpOpen = true;
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.mobile.$invalid && !this.$v.password.$invalid) {
        const formData = new FormData();
        formData.append("mobile", this.mobile);
        formData.append("password", this.password);

        this.uploading = true;
        try {
          if (this.role === "CHEF") {
            const chef = await AuthService.loginChef(formData);
            this.setUser(chef);
            this.tokenTimer(chef.expiresIn);
            this.$router
              .push({ name: "chef", params: { chef: chef.id } })
              .catch(() => {});
          } else {
            formData.append("wishlist", JSON.stringify(this.wishlist));
            formData.append(
              "checkout",
              JSON.stringify(
                this.checkout.map(dish => {
                  return {
                    id: dish.id,
                    selectedNumberOfPersons: dish.selectedNumberOfPersons
                  };
                })
              )
            );
            const user = await AuthService.loginUser(formData);
            this.wishlist = user.wishlist;
            this.checkout = user.checkout;
            this.setUser(user);
            this.tokenTimer(user.expiresIn);
          }
        } catch {
        } finally {
          this.uploading = false;
          this.clearForm();
        }
      }
    },
    async userSignUp() {
      this.$v.$touch();
      if (
        !this.$v.mobile.$invalid &&
        !this.$v.password.$invalid &&
        !this.$v.passwordConfirm.$invalid
      ) {
        const formData = new FormData();
        formData.append("mobile", this.mobile);
        formData.append("password", this.password);
        formData.append("passwordConfirm", this.passwordConfirm);

        this.uploading = true;
        try {
          await AuthService.registerUser(formData);
          this.isOtpPopUpOpen = true;
          this.$root.$alert.success({
            message: this.$t("success.register")
          });
          this.newUser = false;
        } catch {
        } finally {
          this.uploading = false;
          this.clearForm();
        }
      }
    },
    clearForm() {
      this.$v.$reset();
      this.password = "";
    },
    tokenTimer(duration) {
      setTimeout(() => {
        this.logout();
      }, duration * 1000);
    },
    logOut() {
      this.role === "CHEF" ? "" : this.clearCart();
      this.logout();
      this.role === "CHEF"
        ? this.$router.push({ name: "home" }).catch(() => {})
        : this.refresh();
      this.role = "USER";
    }
  },
  validations: {
    mobile: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(12),
      numeric,
      mobileValid: function(value) {
        return /^966[0-9]{9}$/.test(value);
      }
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(24),
      containsUppercase: function(value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        return /[!@#$%^&*(),.?"':;{/}[\]|<~>§±<->_+\-=\\`]/.test(value);
      }
    },
    passwordConfirm: { sameAsPassword: sameAs("password") },
    passwordCurrent: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(24),
      containsUppercase: function(value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        return /[!@#$%^&*(),.?"':;{/}[\]|<~>§±<->_\-+=\\`]/.test(value);
      }
    }
  }
};
</script>

<style scoped>
#logout {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
