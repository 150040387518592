<template>
  <div class="text-center">
    <v-dialog :value="value" width="350" @click:outside="value = false">
      <v-card class="text-center">
        <v-card-actions @click="value = false">
          <v-icon>fas fa-times</v-icon>
        </v-card-actions>
        <v-card-title class="pt-0">
          <v-icon class="mx-auto" :color="color" :size="80">{{ icon }}</v-icon>
        </v-card-title>
        <v-card-text class="headline lighten-2 my-2">
          <b>{{ title }}</b>
        </v-card-text>
        <v-card-text class="lighten-2 pb-12">{{ message }}</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      color: "",
      icon: "",
      message: "",
      title: "",
      type: "",
      value: false
    };
  },
  methods: {
    open({ type, title, message }) {
      this.type = type;
      this.title = title;
      this.message = message;
      this.value = true;
    },
    info(payload) {
      this.color = "";
      this.icon = "";
      return this.open({
        type: "info",
        title: payload.title
      });
    },
    success(payload) {
      this.color = "success";
      this.icon = "fi fi-rr-check";
      return this.open({
        type: "success",
        title: payload.title,
        message: payload.message
      });
    },
    error(payload) {
      this.color = "error";
      this.icon = "fi fi-rr-cross-small";
      return this.open({
        type: "error",
        title: payload.title || this.$t("common.error"),
        message: payload.message
      });
    }
  },
  created() {
    this.$root.$alert = {
      info: this.info,
      error: this.error,
      success: this.success,
      open: this.open
    };
  }
};
</script>
