<template>
  <v-card class="mb-0">
    <v-app-bar v-show="!scroll">
      <v-btn class="px-0" medium icon @click="profileDrawer = true">
        <v-icon v-if="!token" size="17">fi fi-rr-user</v-icon>
        <v-icon v-else size="17" color="primary">fi fi-sr-user</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <a href="https://cheflk.com">
        <v-img
          @click="$router.push({ name: 'home' }).catch(() => {})"
          contain
          :height="$vuetify.breakpoint.mdAndUp ? 55 : 45"
          width="250"
          :src="require('@/assets/logo.webp')"
        />
      </a>
      <v-spacer></v-spacer>
      <u
        class="text-caption"
        :class="$route.params.lang === 'ar' ? 'mr-5' : ''"
        style="cursor: pointer;"
        @click="changeLocal"
        >{{ $t("appHeader.language") }}</u
      >
    </v-app-bar>

    <v-app-bar
      v-if="$vuetify.breakpoint.mdAndUp"
      :elevation="scroll ? 3 : 0"
      dense
      flat
      :fixed="scroll"
    >
      <v-tabs :grow="scroll">
        <v-tab @click="scrollTop" v-show="scroll" :to="{ name: 'home' }">
          <v-img
            contain
            height="35"
            width="10"
            :src="require('@/assets/logo.webp')"
        /></v-tab>
        <v-tab @click="scrollTop" :to="{ name: 'chefs' }">{{
          $t("appHeader.tabs.chefs")
        }}</v-tab>
        <v-tab @click="scrollTop" :to="{ name: 'dishes' }">{{
          $t("appHeader.tabs.dishes")
        }}</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn
        v-if="role !== 'CHEF' || !token"
        @click="$router.push({ name: 'wishlist' })"
        medium
        icon
      >
        <v-badge
          :left="$route.params.lang === 'ar' ? true : false"
          v-if="wishlist.dishes.length || wishlist.chefs.length"
          overlap
          dot
        >
          <v-icon class="mx-lg-1" :color="currentUrlIsWishlist()" small
            >fi fi-rr-star</v-icon
          >
        </v-badge>
        <v-icon v-else class="mx-lg-1" :color="currentUrlIsWishlist()" small
          >fi fi-rr-star</v-icon
        >
      </v-btn>
      <v-btn
        v-if="role !== 'CHEF' || !token"
        @click="$router.push({ name: 'checkout' })"
        medium
        icon
      >
        <v-badge
          :left="$route.params.lang === 'ar' ? true : false"
          :color="checkout.length ? 'primary' : ''"
          overlap
          dot
        >
          <v-icon :color="currentUrlIsCheckout()" class="mx-lg-1" small
            >fi fi-rr-shopping-cart</v-icon
          >
        </v-badge>
      </v-btn>
    </v-app-bar>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "AppHeader",
  computed: {
    ...mapFields("main", ["scroll", "profileDrawer"]),
    ...mapFields("carts", ["wishlist", "checkout"]),
    ...mapFields("auth", ["token", "role"])
  },
  methods: {
    changeLocal() {
      //Change i18n locale
      if (this.$route.params.lang === "en") {
        this.$i18n.locale = "ar";
        const routePath = "/ar" + this.$route.fullPath.substring(3);
        this.$router.push({ path: routePath });
      } else {
        this.$i18n.locale = "en";
        const routePathEN = "/en" + this.$route.fullPath.substring(3);
        this.$router.push({ path: routePathEN });
      }
      //RtL
      if (this.$route.params.lang === "en") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      location.reload();
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    currentUrlIsCheckout() {
      return this.$route.name === "checkout" ? "primary" : "";
    },
    currentUrlIsWishlist() {
      return this.$route.name === "wishlist" ? "primary" : "";
    }
  }
};
</script>
