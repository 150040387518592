<template>
  <div>
    <v-footer
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 18%;' : 'margin-bottom: 20%;'
      "
      dark
      padless
    >
      <v-card width="100%" flat tile class="white--text text-center">
        <v-card-text>
          <v-btn
            :href="item.href"
            :aria-label="item.href"
            target="_blank"
            v-for="item in icons"
            :key="item.icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0 px-lg-12">
          {{ $t("AppFooter.about") }}
        </v-card-text>

        <v-card-subtitle class="my-0">
          <v-btn
            :to="{ name: 'terms-and-conditions' }"
            class="px-1"
            color="red darken-4"
            text
          >
            <b>{{ this.$t("common.termsAndConditionsOnly") }}</b>
          </v-btn>
        </v-card-subtitle>
        <v-card-subtitle class="my-0">
          <v-btn
            :to="{ name: 'privacy' }"
            class="px-1"
            color="red darken-4"
            text
          >
            <b>{{ this.$t("common.privacy") }}</b>
          </v-btn>
        </v-card-subtitle>

        <v-divider></v-divider>
        <v-icon medium class="mx-2 mt-2">fa-cc-visa</v-icon>
        <v-icon medium class="mx-2 mt-2">fa-cc-mastercard</v-icon>

        <v-card-text class="white--text">
          <strong>
            {{ new Date().getFullYear() }} —
            {{ $t("AppFooter.copyRight") }}
          </strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <v-btn
      v-if="checkout.length > 0 && role !== 'CHEF'"
      style="position: fixed; bottom: 12%; right:3%"
      color="primary"
      fixed
      bottom
      right
      elevation="2"
      fab
      raised
      :to="{ name: 'checkout' }"
    >
      <v-badge
        :left="$route.params.lang === 'ar' ? true : false"
        :content="checkout.length"
        color="grey lighten-2 black--text font-weight-bold"
      >
        <v-icon color="white">fi fi-rr-shopping-cart</v-icon>
      </v-badge>
    </v-btn>
    <v-bottom-navigation
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="elevation-4 mt-12"
      height="85"
      app
    >
      <v-btn class="pb-4" :to="{ name: 'chefs' }">
        <span
          :class="
            $route.name === 'chefs'
              ? 'text-caption-3 font-weight-thin mt-1 pt-1 primary--text'
              : 'text-caption-3 font-weight-thin mt-1 pt-1 grey--text text--lighten-1'
          "
          >{{ $t("appHeader.tabs.chefs") }}</span
        >
        <v-icon :color="$route.name === 'chefs' ? 'primary' : 'grey lighten-1'"
          >fi fi-{{ $route.name === "chefs" ? "rr" : "tr" }}-hat-chef</v-icon
        >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="pb-4" :to="{ name: 'dishes' }" @click="scrollUp()">
        <span
          :class="
            $route.name === 'dishes'
              ? 'text-caption-3 font-weight-thin mt-1 pt-1 primary--text'
              : 'text-caption-3 font-weight-thin mt-1 pt-1 grey--text text--lighten-1'
          "
          >{{ $t("appHeader.tabs.dishes") }}</span
        >
        <v-icon :color="$route.name === 'dishes' ? 'primary' : 'grey'"
          >fi fi-{{ $route.name === "dishes" ? "br" : "ts" }}-restaurant
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn class="pb-4" :to="{ name: 'orders' }">
        <span
          :class="
            $route.name === 'orders'
              ? 'text-caption-3 font-weight-thin mt-1 pt-1 primary--text'
              : 'text-caption-3 font-weight-thin mt-1 pt-1 grey--text text--lighten-1'
          "
          >{{ $t("AppFooter.orders") }}</span
        >
        <v-icon
          size="27"
          :color="$route.name === 'orders' ? 'primary' : 'grey lighten-1'"
          >fi fi-{{
            $route.name === "orders" ? "rr" : "tr"
          }}-concierge-bell</v-icon
        >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="pb-4" :to="{ name: 'wishlist' }">
        <span
          :class="
            $route.name === 'wishlist'
              ? 'text-caption-3 font-weight-thin mt-1 pt-1 primary--text'
              : 'text-caption-3 font-weight-thin mt-1 pt-1 grey--text text--lighten-1'
          "
          >{{ $t("AppFooter.likes") }}</span
        >
        <v-badge
          :left="$route.params.lang === 'ar' ? true : false"
          v-if="wishlist.dishes.length || wishlist.chefs.length"
          overlap
          dot
          class="pt-1"
        >
          <v-icon
            :color="$route.name === 'wishlist' ? 'primary' : 'grey lighten-2'"
            >fi fi-{{ $route.name === "dishes" ? "br" : "rr" }}-star</v-icon
          >
        </v-badge>
        <v-icon
          v-else
          medium
          class="pt-1"
          :color="$route.name === 'wishlist' ? 'primary' : 'grey lighten-2'"
          >fi fi-{{ $route.name === "dishes" ? "br" : "rr" }}-star</v-icon
        >
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: "AppFooter",
  data: () => ({
    value: null,
    icons: [
      { href: "https://instagram.com/cheflksa/", icon: "fab fa-instagram" },
      { href: "https://twitter.com/cheflksa/", icon: "fab fa-twitter" }
    ]
  }),
  computed: {
    ...mapFields("carts", ["wishlist", "checkout"]),
    ...mapFields("auth", ["role"])
  },
  methods: {
    scrollUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>
