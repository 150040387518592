import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";
import Geocoder from "@pderas/vue2-geocoder";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    region: "SA",
    language: i18n.locale
  },
  installComponents: false
});

Vue.use(Geocoder, {
  defaultCountryCode: "SA",
  defaultLanguage: i18n.locale,
  defaultMode: "24.713241-46.674931",
  googleMapsApiKey: process.env.VUE_APP_MAPS_API_KEY
});
