import { getField, updateField } from "vuex-map-fields";

const state = {
  wishlist: {
    chefs: [],
    dishes: []
  },
  checkout: []
};

const mutations = {
  updateField,
  setSelectedNumberOfPersons: (state, dish) => {
    const itemIndex = state.checkout.findIndex(x => x.id === dish.id);
    state.checkout[itemIndex].selectedNumberOfPersons = dish.quantity;
  },
  setWishlistDishes: (state, dishes) => {
    state.wishlist.dishes = dishes;
  },
  addDishToCheckout: (state, item) => {
    state.checkout.push(item);
  },
  removeDishFromCheckout: (state, id) => {
    state.checkout = state.checkout.filter(item => item.id !== id);
  },
  addDishToWishlist: (state, item) => {
    state.wishlist.dishes.push(item);
  },
  removeDishFromWishlist: (state, dishId) => {
    state.wishlist.dishes = state.wishlist.dishes.filter(id => id !== dishId);
  },
  addChefToWishlist: (state, item) => {
    state.wishlist.chefs.push(item);
  },
  removeChefFromWishlist: (state, chefId) => {
    state.wishlist.chefs = state.wishlist.chefs.filter(id => id !== chefId);
  },
  clearCart(state) {
    state.wishlist.dishes = [];
    state.wishlist.chefs = [];
    state.checkout = [];
  },
  clearCheckout(state) {
    state.checkout = [];
  }
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
