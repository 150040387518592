<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @input="$emit('input', $event)"
    @keydown.esc="$emit('esc-key-down', $event)"
    :persistent="loading || persistent"
    :scrollable="scrollable"
    :fullscreen="$vuetify.breakpoint.smAndDown ? fullscreen : false"
  >
    <v-card>
      <v-toolbar dark :color="color">
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="text || $slots.default" class="mt-2 px-4 pb-0">
        {{ text }}
        <slot></slot>
      </v-card-text>
      <v-divider class="mt-0"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="!$slots.actions">
          <v-btn
            v-if="!withoutLeftBtn"
            color="grey darken-1"
            text
            :disabled="!withoutRightBtn && loading"
            @click="$emit('left-btn-click')"
          >
            {{ leftBtnText }}
          </v-btn>
          <v-btn
            v-if="!withoutRightBtn"
            color="primary"
            text
            @click="$emit('right-btn-click')"
            :loading="loading"
          >
            {{ rightBtnText }}
          </v-btn>
        </template>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    value: null,
    title: String,
    text: String,
    maxWidth: {
      type: [Number, String],
      default: 500
    },
    color: {
      type: String,
      default: "primary"
    },
    leftBtnText: {
      type: String,
      default() {
        return this.$t("common.cancel");
      }
    },
    rightBtnText: {
      type: String,
      default() {
        return this.$t("common.ok");
      }
    },
    withoutLeftBtn: Boolean,
    withoutRightBtn: Boolean,
    loading: Boolean,
    persistent: Boolean,
    scrollable: {
      type: Boolean,
      default: false
    },
    fullscreen: { type: Boolean, default: false }
  }
};
</script>
