<template>
  <v-card
    :loading="loading"
    :elevation="hover ? 12 : 3"
    class="mt-1 mx-auto text-center overflow-hidden"
    :height="
      expandCard
        ? $vuetify.breakpoint.mdAndUp
          ? 480
          : 'auto'
        : $route.name === 'checkout'
        ? 335
        : 319
    "
    :max-width="expandCard ? 'auto' : 320"
  >
    <v-btn
      v-if="id === dish.chef && dish.imgs && dish.imgs.length === 0"
      @click="isEditDishPopupOpen = true"
      class="d-flex d-flex-block"
      icon
    >
      <v-icon color="info" medium>fas fa-edit</v-icon>
    </v-btn>

    <v-row row :justify="expandCard ? 'space-around' : 'center'">
      <v-col
        class="mt-0 pt-0 pb-0"
        :cols="expandCard && $vuetify.breakpoint.mdAndUp ? 6 : 12"
      >
        <v-carousel
          :hide-delimiters="dish.imgs ? dish.imgs.length < 2 : ''"
          :show-arrows="dish.imgs ? dish.imgs.length > 1 : ''"
          :hide-delimiter-background="!hover"
          show-arrows-on-hover
          :height="
            expandCard
              ? $vuetify.breakpoint.mdAndUp
                ? dish.isMenu
                  ? 460
                  : 480
                : 280
              : $route.name === 'checkout'
              ? 185
              : 210
          "
        >
          <v-card-subtitle
            v-if="dish.imgs && dish.imgs.length === 0"
            class="text-center"
          >
            {{ $t("dish.dishForm.uploadImage") }}
          </v-card-subtitle>
          <v-carousel-item
            v-for="(img, index) in dish.imgs"
            :key="index"
            :src="imgUrl(img)"
          >
            <v-row class="pa-0 ma-0" row>
              <v-col cols="1" class="pa-0 ma-0">
                <v-btn
                  v-if="role === 'CHEF' && token && id === $route.params.chef"
                  @click="isEditDishPopupOpen = true"
                  icon
                >
                  <v-icon color="info" medium>fas fa-edit</v-icon>
                </v-btn>

                <v-btn class="pa-0 ma-0" v-if="role !== 'CHEF'" icon>
                  <v-icon
                    color="primary"
                    @click="removeWishlist"
                    v-if="onWishlist"
                    medium
                    >fas fa-star</v-icon
                  >
                  <v-icon class="starIcon" @click="addWishlist" v-else medium
                    >far fa-star</v-icon
                  >
                </v-btn>
              </v-col>
              <v-spacer
                :style="$route.name !== 'dish' ? 'cursor: pointer;' : ''"
                class="mx-5"
                @click="dishView"
              ></v-spacer>

              <v-col cols="2" class="pa-0 my-0 mx-0">
                <v-btn
                  v-if="role === 'CHEF' && token && id === $route.params.chef"
                  icon
                >
                  <v-icon
                    @click="ConfirmRemoveDishCheckout(dish.name)"
                    color="primary"
                    medium
                    >fas fa-trash
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="$route.name === 'checkout'"
                  @click="ConfirmRemoveDishCheckout(dish.name)"
                  icon
                >
                  <v-icon color="primary" medium>fas fa-trash</v-icon>
                </v-btn>
              </v-col>

              <ConfirmDialog
                @canceled="isConfirmDialogOpen = false"
                @confirmed="
                  role === 'CHEF' && token && id === $route.params.chef
                    ? removeDish(dish.id)
                    : removeDishCheckout(dish.id)
                "
                :isOpen="isConfirmDialogOpen"
                :text="confirmDialogText"
              />

              <v-dialog v-model="fileDeletingDialog" persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text class="pt-2 pb-2">
                    {{ fileDeletingDialog ? $t("common.deleting") : "" }}
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-0 py-0">
                <v-card
                  style="opacity: 0;"
                  class="mt-1 mb-0"
                  @click="dishView"
                  width="20em"
                  :height="dish.imgs.length > 1 ? '7.5em' : '10em'"
                ></v-card>
              </v-col>
            </v-row>
            <v-row
              :style="$route.name !== 'dish' ? 'cursor: pointer;' : ''"
              class="text-end mt-0"
              @click="dishView"
            >
              <v-spacer
                :style="$route.name !== 'dish' ? 'cursor: pointer;' : ''"
                class="mx-5"
                @click="dishView"
              ></v-spacer>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <v-card
          tile
          v-if="dish.isMenu"
          color="primary"
          class="font-weight-bold white--text py-0 caption"
          width="100%"
          >{{ $t("dish.menu") }}</v-card
        >
      </v-col>

      <v-col
        :class="expandCard ? '' : 'mt-1 pt-0 pb-0'"
        @click="dishView"
        :style="!expandCard ? 'cursor: pointer;' : ''"
        :cols="expandCard && $vuetify.breakpoint.mdAndUp ? 6 : 12"
      >
        <v-card-title
          :class="
            expandCard
              ? 'pb-2 pt-0 justify-center'
              : 'pb-2 pt-0 justify-center d.flex d-block mx-0'
          "
        >
          {{
            $route.name === "dish" && !expandCard
              ? dish.name.substr(0, 17)
              : dish.name
          }}
          <v-row
            align="center"
            v-show="expandCard || $route.name === 'checkout'"
          >
            <v-spacer></v-spacer>

            <v-btn
              v-show="expandCard"
              color="gray lighten-2"
              @click="$router.go(-1)"
              icon
            >
              <v-icon medium>fi fi-rr-cross-small</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>

        <v-card-subtitle
          :class="expandCard ? ' py-0 text-left' : 'py-0 center'"
        >
          <v-row
            :align="expandCard ? 'start' : 'center'"
            :justify="expandCard ? 'start' : 'center'"
            :class="
              expandCard ? ' px-2 mt-2' : dish.isMenu ? 'mt-2' : 'mb-1 mt-3'
            "
          >
            <v-rating
              :value="dish.stars"
              color="amber"
              dense
              half-increments
              readonly
              :size="expandCard ? 28 : 26"
              background-color="grey lighten-2"
            ></v-rating>
          </v-row>

          <v-card-title v-if="expandCard" class="ma-0 pa-0 subtitle-1">
            {{ dish.price }}

            {{ $t("common.currency") }}
            <span class="caption mx-1 grey--text">
              {{ $t("common.per") }}
            </span>
          </v-card-title>
          <div v-else class="ma-0 pa-0 subtitle-1">
            {{ dish.price }} {{ $t("common.currency") }}
          </div>
          <v-card-title
            style="word-break: break-word"
            :class="
              expandCard
                ? 'ma-0 pa-0 subtitle-2 mt-1 text-start'
                : 'ma-0 pa-0 subtitle-2 mt-1 justify-center'
            "
            v-show="
              $route.name !== 'checkout' &&
                expandCard &&
                $route.name !== 'dishes'
            "
          >
            {{ dish.description }}
          </v-card-title>
          <v-card-title
            v-if="
              expandCard &&
                dish.alergies &&
                $route.name !== 'checkout' &&
                dish.alergies.length > 0
            "
            class="orange--text text--lighten-2 ma-0 pa-0"
            style="font-size: 0.65em;"
          >
            <v-icon class="mb-0" size="8" color="orange lighten-3"
              >fas fa-exclamation-triangle</v-icon
            >
            <span class="mx-1">{{ $t("dish.allergies") }} </span>
            <span v-for="(item, index) in dish.alergies" :key="index"
              >{{ getAlergiesText(item) }}
              <span v-if="index + 1 < dish.alergies.length">, </span>
            </span>
          </v-card-title>
        </v-card-subtitle>

        <v-expand-transition>
          <div class="mt-lg-3 mt-5 mt-lg-12 pt-lg-12" v-show="expandCard">
            <v-row row justify="center" align="center">
              <v-col cols="10" class="px-0 mb-3">
                <v-icon
                  @click="selectedNumberOfPersons--"
                  :disabled="
                    selectedNumberOfPersons <= dish.minNumberOfPersons
                      ? true
                      : false
                  "
                  color="primary"
                  medium
                >
                  far fa-minus-square
                </v-icon>
                <span class="mx-2">
                  {{ selectedNumberOfPersons }}
                  {{
                    selectedNumberOfPersons === 1
                      ? $t("common.person")
                      : $t("common.persons")
                  }}
                </span>
                <v-icon
                  @click="selectedNumberOfPersons++"
                  :disabled="
                    selectedNumberOfPersons >= dish.maxNumberOfPersons
                      ? true
                      : false
                  "
                  color="primary"
                  medium
                >
                  far fa-plus-square
                </v-icon>
              </v-col>
            </v-row>

            <v-row class="mt-lg-0" justify="center" align="end" row>
              <v-card-actions
                class="my-0 py-0 px-0 mt-lg-8"
                justify="center"
                align="center"
                v-show="expandCard && role !== 'CHEF' ? true : false"
              >
                <v-btn
                  v-if="!isDishModified"
                  color="black"
                  outlined
                  @click="addAndPushToCheckout"
                >
                  {{ $t("checkout.order") }}
                </v-btn>
                <v-btn
                  v-if="!isDishModified && !selectedNumberOfPersonsFromCheckout"
                  class="white--text"
                  color="primary"
                  @click="addCheckout"
                >
                  {{ $t("common.addCart") }}
                </v-btn>
                <v-btn
                  v-if="isDishModified"
                  color="blue"
                  outlined
                  @click="addAndPushToCheckout"
                >
                  {{ $t("common.modify") }}
                </v-btn>
              </v-card-actions>
            </v-row>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-row row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12">
        <v-card-actions class="mt-0 pt-0" v-if="$route.name === 'checkout'">
          <v-btn
            class="pa-0 ma-0 font-weight-bold"
            block
            color="blue"
            @click="dishView"
            text
          >
            {{ $t("common.modify") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <DishForm
      :editDish="dish.id"
      :isOpen="isEditDishPopupOpen"
      @close-popup="isEditDishPopupOpen = false"
    />
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";
import DishService from "@/services/api/DishService";
import UserService from "@/services/api/UserService";
import ConfirmDialog from "@/components/ConfirmDialog";
import DishForm from "@/components/DishForm";

export default {
  name: "DishCard",
  props: {
    dish: Object,
    expandCard: Boolean,
    hover: Boolean,
    loading: Boolean
  },
  components: { DishForm, ConfirmDialog },
  data() {
    return {
      fileDeletingDialog: false,
      isConfirmDialogOpen: false,
      confirmDialogText: "",
      isEditDishPopupOpen: false,
      selectedNumberOfPersons: 0,
      selectedTime: 0,
      onWishlist: false,
      isDishModified: false,
      selectedNumberOfPersonsFromCheckout: null,
      price: 0
    };
  },
  computed: {
    ...mapFields("auth", ["id", "status", "token", "role"]),
    ...mapFields("carts", ["wishlist", "checkout"]),
    ...mapFields("main", ["materials"]),
    calculatePrice() {
      return this.$route.name === "checkout"
        ? this.dish.selectedNumberOfPersons * this.dish.price
        : this.selectedNumberOfPersons * this.dish.price;
    },
    numberOfPersons() {
      return this.$route.name === "checkout"
        ? this.dish.selectedNumberOfPersons
        : this.selectedNumberOfPersons;
    }
  },
  methods: {
    ...mapMutations("carts", [
      "addDishToWishlist",
      "removeDishFromWishlist",
      "addDishToCheckout",
      "removeDishFromCheckout"
    ]),
    async removeDish(id) {
      const formData = new FormData();
      formData.append("dishId", id);
      this.fileDeletingDialog = true;
      try {
        await DishService.removeDish(formData);
      } catch {
      } finally {
        this.isConfirmDialogOpen = false;
        this.fileDeletingDialog = false;
        window.location.reload();
      }
    },
    imgUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    },
    getMaterialsText(item) {
      return this.materials
        .filter(material => material.value === item.value)
        .map(material => material.text)[0];
    },
    getAlergiesText(value) {
      return this.$t("lists.alergies")
        .filter(alergie => alergie.value === value)
        .map(alergie => alergie.text)[0];
    },
    setSelectedNumberOfPersons() {
      this.checkout.filter(dish => {
        if (dish.id === this.dish.id) {
          this.selectedNumberOfPersons = dish.selectedNumberOfPersons;
          this.selectedNumberOfPersonsFromCheckout =
            dish.selectedNumberOfPersons;
        }
      });
    },
    ConfirmRemoveDishCheckout(name) {
      this.confirmDialogText = this.$t("common.remove") + name;
      this.isConfirmDialogOpen = true;
    },
    removeDishCheckout(id) {
      this.token ? this.removeCheckoutDish() : "";
      this.removeDishFromCheckout(id);
    },
    async removeCheckoutDish() {
      const formData = new FormData();
      formData.append("dishId", this.dish.id);
      try {
        await UserService.removeCheckoutDish(formData);
      } catch {}
    },
    updateDish() {
      if (this.isDishModified) {
        this.removeDishFromCheckout(this.dish.id);
        this.addDishToCheckout({
          id: this.dish.id,
          selectedNumberOfPersons: this.selectedNumberOfPersons
        });
      } else {
        this.$root.$alert.error({ title: this.$t("error.dishExists") });
      }
    },
    async uploadCheckoutDish() {
      const formData = new FormData();
      formData.append("id", this.dish.id);
      formData.append("selectedNumberOfPersons", this.selectedNumberOfPersons);
      try {
        await UserService.addCheckoutDish(formData);
      } catch {}
    },
    async uploadCheckoutDishNotLoggedIn() {
      try {
        await UserService.addCheckoutDishNotLoggedIn({
          id: this.dish.id,
          number: this.selectedNumberOfPersons
        });
      } catch {}
    },
    addCheckout() {
      this.dish["selectedNumberOfPersons"] = this.selectedNumberOfPersons;
      this.checkout &&
      this.checkout.filter(dish => dish.id === this.dish.id).length > 0
        ? this.updateDish()
        : this.addDishToCheckout({
            id: this.dish.id,
            selectedNumberOfPersons: this.selectedNumberOfPersons
          });
      this.token
        ? this.uploadCheckoutDish()
        : this.uploadCheckoutDishNotLoggedIn();
      this.$root.$alert.success({ title: this.$t("success.dishCheckout") });
    },
    addAndPushToCheckout() {
      this.addCheckout();
      this.$router.push({ name: "checkout" });
    },
    async uploadWishlistDish() {
      const formData = new FormData();
      formData.append("dishId", this.dish.id);
      try {
        await UserService.addWishlistDish(formData);
      } catch {}
    },
    addWishlist() {
      if (this.token) {
        this.uploadWishlistDish();
      }
      this.addDishToWishlist(this.dish.id);
      this.onWishlist = true;
    },
    async removeWishlistDish() {
      const formData = new FormData();
      formData.append("dishId", this.dish.id);
      try {
        await UserService.removeWishlistDish(formData);
      } catch {}
    },
    removeWishlist() {
      if (this.token) {
        this.removeWishlistDish();
      }
      this.removeDishFromWishlist(this.dish.id);
      this.onWishlist = false;
    },
    dishView() {
      if (!this.expandCard && this.dish.imgs.length > 0) {
        this.$router.push({
          name: "dish",
          params: {
            dish: `${this.dish.name
              .split(" ")
              .map(name => name.toLowerCase())
              .filter(name => name !== " ")
              .join("-")}-${this.dish.id}`
          }
        });
        this.$router.go(0);
      }
    }
  },
  watch: {
    selectedNumberOfPersons() {
      if (
        this.selectedNumberOfPersonsFromCheckout &&
        this.selectedNumberOfPersonsFromCheckout !==
          this.selectedNumberOfPersons
      ) {
        this.isDishModified = true;
      } else {
        this.setSelectedNumberOfPersons();

        this.isDishModified = false;
      }
    },
    dish() {
      this.selectedNumberOfPersons = this.dish.minNumberOfPersons;
    }
  },
  created() {
    this.selectedNumberOfPersons = this.dish.minNumberOfPersons;
    this.setSelectedNumberOfPersons();

    if (this.wishlist.dishes)
      this.wishlist.dishes.filter(id => id === this.dish.id).length > 0
        ? (this.onWishlist = true)
        : (this.onWishlist = false);
  }
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
.starIcon {
  text-shadow: 0.3px 0.3px black;
}
</style>
