<template>
  <v-card
    :elevation="hover ? 12 : 3"
    class="my-1 mx-auto text-center overflow-hidden"
    :height="$vuetify.breakpoint.smAndDown ? 270 : 335"
  >
    <v-img
      :height="sortByCategory ? 200 : $vuetify.breakpoint.smAndDown ? 200 : 260"
      :src="
        chef.img && chef.img.length === 0
          ? require('@/assets/becomeChef.webp')
          : chefImageUrl(chef.img)
      "
    >
      <v-row row
        ><v-col class="pa-0 mx-3 ma-0" cols="2">
          <v-btn v-if="role !== 'CHEF'" color="white" icon>
            <v-icon
              @click="removeWishlist"
              v-if="onWishlist"
              color="primary"
              medium
              >fas fa-star</v-icon
            >
            <v-icon class="starIcon" @click="addWishlist" v-else medium
              >far fa-star</v-icon
            >
          </v-btn>
        </v-col>

        <v-spacer style="cursor: pointer;" @click="chefView"></v-spacer>
      </v-row>

      <v-card
        v-if="$route.name !== 'chef'"
        class="mt-1"
        style="opacity: 0;"
        @click="chefView"
        width="20em"
        height="15em"
      ></v-card>
    </v-img>

    <v-card
      tile
      class="ma-0 pa-0"
      elevation="0"
      height="100%"
      @click="chefView"
    >
      <v-card-title class="py-0 mt-1 justify-center">
        {{ chef.name }}
      </v-card-title>

      <v-row align="center" justify="center" class="mx-0 my-0">
        <v-rating
          :value="chef.stars"
          color="amber"
          dense
          half-increments
          readonly
          :size="$vuetify.breakpoint.smAndDown ? 27 : 30"
          background-color="grey lighten-2"
        ></v-rating>
      </v-row>

      <div class="subtitle-1">
        <v-card-text class="pb-4 mt-0 pt-2">
          <v-chip
            pill
            style="cursor: pointer;"
            v-for="category in chefCategories(chef.categories)"
            :key="category"
            :color="changeCategoryCalour(category)"
            class="ma-1 pa-2 font-weight-bold"
            small
            outlined
          >
            {{ getCategoryText(category) }}
          </v-chip>
        </v-card-text>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";
import UserService from "@/services/api/UserService";

export default {
  name: "ChefCard",
  props: { chef: Object, sortByCategory: String, hover: Boolean },
  computed: {
    ...mapFields("auth", ["token", "role"]),
    ...mapFields("carts", ["wishlist"]),
    ...mapFields("main", ["categories"])
  },
  data() {
    return {
      onWishlist: false
    };
  },
  methods: {
    changeCategoryCalour(key) {
      return this.sortByCategory == key ? "primary" : "gray";
    },
    chefCategories(categories) {
      return categories.length < 6 ? categories : categories.slice(0, 6);
    },
    getCategoryText(value) {
      return this.categories
        .filter(category => category.value === value)
        .map(x => x.text)[0];
    },
    ...mapMutations("carts", ["addChefToWishlist", "removeChefFromWishlist"]),
    async uploadWishlistChefs() {
      const formData = new FormData();
      formData.append("chefId", this.chef.id);
      try {
        await UserService.addWishlistChefs(formData);
      } catch {}
    },
    addWishlist() {
      if (this.token) {
        this.uploadWishlistChefs();
      }
      this.addChefToWishlist(this.chef.id);
      this.onWishlist = true;
    },
    async removeWishlistChef() {
      const formData = new FormData();
      formData.append("chefId", this.chef.id);
      try {
        await UserService.removeWishlistChefs(formData);
      } catch {}
    },
    removeWishlist() {
      if (this.token) {
        this.removeWishlistChef();
      }
      this.removeChefFromWishlist(this.chef.id);
      this.onWishlist = false;
    },
    chefView() {
      if (!this.expandCard) {
        this.$router.push({
          name: "chef",
          params: {
            chef: `${this.chef.name
              .split(" ")
              .map(name => name.toLowerCase())
              .join("-")}-${this.chef.id}`
          }
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    chefImageUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    }
  },
  mounted() {
    if (this.wishlist.chefs)
      this.wishlist.chefs.filter(id => id === this.chef.id).length > 0
        ? (this.onWishlist = true)
        : (this.onWishlist = false);
  }
};
</script>
<style scoped>
.starIcon {
  text-shadow: 0.3px 0.3px black;
}
</style>
