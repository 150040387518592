<template>
  <Dialog
    :value="isOpen"
    @input="$emit('input', $event)"
    :title="$t('common.rate')"
    @left-btn-click="$emit('close-popup')"
    @right-btn-click="rate"
    :loading="loading"
    :rightBtnText="$t('common.submit')"
    :persistent="true"
  >
    <v-form class="py-4">
      {{ $t("common.chef") }}
      <v-divider></v-divider>
      <v-row align="center" justify="center" class="mx-0 mt-2 mb-3">
        <v-col cols="5"
          ><h3>{{ chefName }}</h3>
        </v-col>
        <v-col cols="7">
          <v-rating
            v-model="chefRating"
            color="amber"
            dense
            size="35"
          ></v-rating>
        </v-col>
      </v-row>
      {{ $t("appHeader.tabs.dishes") }}
      <v-divider></v-divider>
      <div v-for="dish in dishes" :key="dish.id">
        <v-row align="center" justify="center" class="mx-0 mt-2">
          <v-col cols="5" class="px-0">
            <h3>{{ dish.name }}</h3></v-col
          >
          <v-col cols="7">
            <v-rating
              v-model="dish.stars"
              color="amber"
              dense
              size="35"
            ></v-rating>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </Dialog>
</template>

<script>
import Dialog from "./Dialog";
import OrderService from "@/services/api/OrderService";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  components: {
    Dialog
  },
  props: {
    isOpen: Boolean,
    orderId: { type: String, default: "" },
    chefName: { type: String, default: "" },
    dishes: { type: Array, default: new Array() }
  },
  data() {
    return {
      chefRating: 0,
      loading: false
    };
  },
  watch: {
    isOpen(val) {
      val
        ? this.dishes
          ? this.dishes.map(dish => {
              dish.stars = 0;
            })
          : ""
        : "";
    }
  },
  methods: {
    async rate() {
      const rateFormData = new FormData();
      rateFormData.append("orderId", this.orderId);
      rateFormData.append("chefRating", this.chefRating);
      rateFormData.append(
        "dishes",
        JSON.stringify(
          this.dishes.map(dish => ({
            id: dish.id,
            stars: dish.stars
          }))
        )
      );

      try {
        this.loading = true;
        await OrderService.rate(rateFormData);
        this.$root.$alert.success({
          message: this.$t("success.orderRated")
        });
        this.$emit("close-popup");
      } catch (e) {
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.dishes
      ? this.dishes.map(dish => {
          dish.stars = 0;
        })
      : "";
  }
};
</script>
