<template>
  <v-container class="mt-2">
    <v-data-iterator
      :items="filteredItems"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hide-default-footer
      multi-sort
      :loading="loading"
    >
      <template v-slot:loading>
        <v-row>
          <v-col
            v-for="n in 4"
            :key="n"
            cols="12"
            :xs="type === 'dish' && expandCard ? 12 : 3"
            :sm="type === 'dish' && expandCard ? 12 : 3"
            :md="type === 'dish' && expandCard ? 12 : 4"
            :lg="type === 'dish' && expandCard ? 12 : withChef ? 4 : 3"
          >
            <v-sheet :color="'grey lighten-4'" class="pa-3">
              <v-skeleton-loader
                :boilerplate="true"
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-sheet> </v-col
        ></v-row>
      </template>
      <template v-slot:no-data>
        <v-card class="mt-6" solo elevation="0">
          <v-row justify="center" align="center">
            <v-card-title class="my-12 text--center">
              {{ $t("common.noResult") }}
            </v-card-title>
          </v-row>

          <v-row v-if="role === 'USER'" justify="center" align="center" row>
            <v-btn
              v-if="type === 'dish'"
              :to="{ name: 'dishes' }"
              class="my-8 font-weight-bold"
              x-large
              color="white"
              >{{ $t("common.add") + " " + $t("appHeader.tabs.dishes") }}
              <v-icon class="mx-1" color="primary">fi fi-rr-utensils</v-icon>
            </v-btn>
            <v-btn
              v-else
              :to="{ name: 'chefs' }"
              class="my-8 font-weight-bold"
              x-large
              color="white"
              >{{ $t("common.add") + " " + $t("appHeader.tabs.chefs") }}
              <v-icon class="mx-1" color="primary">fi fi-rr-hat-chef</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </template>
      <template v-slot:no-results>
        <v-row justify="center" align="center">
          <v-card-title class="my-12 text--center">
            {{ $t("common.noResult") }}
          </v-card-title>
        </v-row>

        <v-row v-if="role === 'USER'" justify="center" align="center" row>
          <v-btn
            v-if="type === 'dish'"
            :to="{ name: 'dishes' }"
            class="my-8 font-weight-bold"
            x-large
            color="white"
            >{{ $t("common.add") + " " + $t("appHeader.tabs.dishes") }}
            <v-icon class="mx-1" color="primary">fi fi-rr-utensils</v-icon>
          </v-btn>
          <v-btn
            v-else
            :to="{ name: 'chefs' }"
            class="my-8 font-weight-bold"
            x-large
            color="white"
            >{{ $t("common.add") + " " + $t("appHeader.tabs.chefs") }}
            <v-icon class="mx-1" color="primary">fi fi-rr-hat-chef</v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:header>
        <v-toolbar
          elevation="1"
          dense
          height="auto"
          rounded
          class="mb-1 px-lg-3"
          v-if="withHeader"
        >
          <v-row row>
            <v-col
              v-if="!$vuetify.breakpoint.mdAndUp"
              cols="9"
              xl="4"
              lg="4"
              md="4"
            >
              <v-select
                v-model="sortByCategory"
                dense
                clearable
                flat
                solo-inverted
                hide-details
                :items="categories"
                :loading="categoriesLoading"
                prepend-inner-icon="fi fi-rr-utensils"
                :label="$t('common.category')"
              ></v-select>
            </v-col>

            <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="2">
              <v-btn
                @click="showFilters = !showFilters"
                height="39"
                depressed
                outlined
                color="grey"
                :value="false"
                class="px-0 mx-0"
              >
                <v-icon>fi fi-rr-filter</v-icon>
                <v-icon v-if="!showFilters" x-small>fas fa-arrow-down</v-icon>
                <v-icon v-if="showFilters" x-small>fas fa-arrow-up</v-icon>
              </v-btn>
            </v-col>

            <template v-if="$vuetify.breakpoint.mdAndUp || showFilters">
              <v-col
                cols="9"
                :xl="sortBy ? 3 : 4"
                :lg="sortBy ? (withChef ? 2 : 3) : 4"
                :md="sortBy ? 3 : 4"
              >
                <v-text-field
                  dense
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="fi fi-rr-search"
                  :label="$t('common.search')"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                cols="9"
                xl="2"
                lg="2"
                md="2"
              >
                <v-select
                  v-model="sortByCategory"
                  dense
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  :items="categories"
                  :loading="categoriesLoading"
                  prepend-inner-icon="fi fi-rr-utensils"
                  :label="$t('common.category')"
                ></v-select>
              </v-col>
              <v-col :cols="sortBy ? 8 : 9" xl="2" lg="2" md="2">
                <v-select
                  dense
                  clearable
                  v-model="sortBy"
                  flat
                  solo-inverted
                  hide-details
                  :items="keys"
                  prepend-inner-icon="fi fi-rr-filter"
                  :label="$t('common.sortBy')"
                  @click="checkPage"
                ></v-select>
              </v-col>
              <v-col v-if="sortBy" cols="1">
                <v-btn-toggle v-model="sortDesc" mandatory>
                  <v-btn
                    height="39"
                    x-small
                    depressed
                    color="white"
                    :value="false"
                  >
                    <v-icon color="black">fas fa-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    height="39"
                    x-small
                    depressed
                    color="white"
                    :value="true"
                  >
                    <v-icon color="black">fas fa-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </template>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            :cols="
              $vuetify.breakpoint.smAndDown && $route.name === 'chefs' ? 6 : 12
            "
            :xs="type === 'dish' && expandCard ? 12 : 3"
            :sm="type === 'dish' && expandCard ? 12 : 3"
            :md="type === 'dish' && expandCard ? 12 : 4"
            :lg="type === 'dish' && expandCard ? 12 : withChef ? 4 : 3"
          >
            <v-hover v-slot:default="{ hover }">
              <v-scroll-x-transition>
                <ChefCard
                  v-if="type === 'chef'"
                  :sortByCategory="sortByCategory"
                  :hover="hover"
                  :chef="item"
                />
                <DishCard
                  v-else-if="type === 'dish'"
                  :hover="hover"
                  :expandCard="expandCard"
                  :dish="item"
                />
              </v-scroll-x-transition>
            </v-hover>
          </v-col>
        </v-row>
      </template>

      <template
        v-slot:footer
        v-if="numberOfPages !== 0 && items && items.length > 4"
      >
        <v-row class="mt-2 mx-1" align="center" justify="center">
          <span class="grey--text">{{ $t("common.ItemsPerPage") }}</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon small>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>
          <div v-if="numberOfPages > 1">
            <span
              class="mx-2
            grey--text"
            >
              {{ page }} / {{ numberOfPages }}
            </span>
            <v-btn
              v-if="page > 1"
              small
              fab
              dark
              color="primary"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon v-if="$route.params.lang === 'en'"
                >fas fa-arrow-left</v-icon
              >
              <v-icon v-else>fas fa-arrow-right</v-icon>
            </v-btn>
            <v-btn
              v-if="page !== numberOfPages"
              small
              fab
              dark
              color="primary"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon v-if="$route.params.lang !== 'en'"
                >fas fa-arrow-left</v-icon
              >
              <v-icon v-else>fas fa-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import DishCard from "@/components/DishCard";
import ChefCard from "@/components/ChefCard";

export default {
  components: { DishCard, ChefCard },
  props: {
    type: String,
    items: Array,
    dark: Boolean,
    withChef: Boolean,
    withHeader: { type: Boolean, default: true },
    itemsPerPage: { type: Number, default: 8 },
    loading: Boolean
  },
  data() {
    return {
      search: "",
      sortDesc: false,
      showFilters: false,
      itemsPerPageArray: [4, 8, 12],
      page: 1,
      sortBy: "",
      sortByCategory: "",
      keys: this.$t("lists.sort"),
      categoriesLoading: false
    };
  },
  computed: {
    ...mapFields("main", ["expandCard", "categories"]),
    ...mapFields("auth", ["role", "token"]),
    numberOfPages() {
      return this.items
        ? Math.ceil(this.filteredItems.length / this.itemsPerPage)
        : 0;
    },
    filteredItems() {
      return this.sortByCategory
        ? this.items.filter(
            item =>
              item.categories.includes(this.sortByCategory) ||
              (this.sortByCategory === "Menu" && item.isMenu === true)
          )
        : this.items;
    }
  },
  methods: {
    checkPage() {
      this.$route.name === "dishes"
        ? this.keys.push(this.$t("price"))
        : (this.keys = this.keys.filter(key => key !== this.$t("price")));
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
      this.$route.name !== "dish"
        ? window.scrollTo({ top: 0, behavior: "smooth" })
        : "";
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
      this.$route.name !== "dish"
        ? window.scrollTo({ top: 0, behavior: "smooth" })
        : "";
    },
    updateItemsPerPage(number) {
      this.$emit("update-items-per-page", number);
    }
  },
  watch: {
    sortBy() {
      this.sortBy === "Stars"
        ? (this.sortDesc = true)
        : (this.sortDesc = false);
      this.sortBy === "Menu" ? (this.sortDesc = false) : (this.sortDesc = true);
      this.page = 1;
    },
    sortByCategory() {
      this.page = 1;
    },
    type() {
      if (this.type === "dish") {
        this.keys.push(this.$t("price"));
        const categories = [this.$t("menu"), ...this.categories];
        this.categories = categories;
      } else {
        this.keys = this.keys.filter(
          key => key.value === this.$t("price") || key.value !== "Menu"
        );
        this.categories = this.categories.filter(key => key.value !== "Menu");
      }
    }
  },
  updated() {
    if (this.type === "dish") {
      this.keys.push(this.$t("price"));
      const categories = [this.$t("menu"), ...this.categories];
      this.categories = categories;
    } else {
      this.keys = this.keys.filter(
        key => key === this.$t("price") || key.value !== "isMenu"
      );
      this.categories = this.categories.filter(key => key.value !== "Menu");
    }
  },
  mounted() {
    this.$route.name === "chef" ? (this.sortBy = "isMenu") : "";
  }
};
</script>
