import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import store from "./store/store";
import i18n from "./plugins/i18n";
import Vuelidate from "vuelidate";
import "./plugins/vueGoogleMaps";
import "./registerServiceWorker";

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (!language) {
    language = "ar";
  }
  i18n.locale = language;
  next();
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  Vuelidate,
  render: h => h(App)
}).$mount("#app");
