import { getField, updateField } from "vuex-map-fields";

const state = {
  scroll: false,
  error: null,
  profileDrawer: false,
  when: {
    date: null,
    time: null
  },
  where: { location: null, geocode: "" },
  notes: "",
  expandCard: false,
  categories: [],
  materials: []
};

const mutations = {
  updateField,
  setError(state, error) {
    state.error = error;
  },
  updateMaterialQuantity: (state, obj) => {
    obj.operation === "increase"
      ? state.materials[state.materials.indexOf(obj.item)].quantity++
      : state.materials[state.materials.indexOf(obj.item)].quantity--;
  },
  addMaterialsHeaders: (state, categories) => {
    categories.map(list => {
      if (list.index === 0) {
        state.materials.unshift({ header: list.category });
      } else if (list.index === 6) {
        state.materials.splice(list.index + 1, 0, {
          header: list.category
        });
      } else {
        state.materials.splice(list.index + 2, 0, {
          header: list.category
        });
      }
    });
  }
};

const actions = {
  setError({ commit }, error) {
    commit("setError", error);
  }
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
