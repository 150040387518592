import RequestService from "../utils/RequestService";

export default {
  addWishlistDish(payload) {
    return RequestService({
      method: "put",
      url: "/users/wishlist/dishes",
      data: payload
    });
  },
  removeWishlistDish(payload) {
    return RequestService({
      method: "post",
      url: "/users/wishlist/dishes/remove",
      data: payload
    });
  },
  addWishlistChefs(payload) {
    return RequestService({
      method: "put",
      url: "/users/wishlist/chefs",
      data: payload
    });
  },
  removeWishlistChefs(payload) {
    return RequestService({
      method: "post",
      url: "/users/wishlist/chefs/remove",
      data: payload
    });
  },
  addCheckoutDish(payload) {
    return RequestService({
      method: "put",
      url: "/users/checkout/dishes",
      data: payload
    });
  },
  removeCheckoutDish(payload) {
    return RequestService({
      method: "post",
      url: "/users/checkout/dishes/remove",
      data: payload
    });
  },
  addCheckoutDishNotLoggedIn(payload) {
    return RequestService({
      method: "get",
      url: `/users/checkout/dish/${payload.id}/${payload.number}`
    });
  }
};
