import axios from "../../shared/axios";
import { getField, updateField } from "vuex-map-fields";

const state = {
  id: localStorage.id,
  token: localStorage.token,
  expiresIn: localStorage.expiresIn,
  role: localStorage.role || "USER",
  status: localStorage.status,
  mobile: "",

  isOtpPopUpOpen: false,
  otpChallenge: "",
  isOtpSuccessful: false,
  forgotPassword: false
};

const getters = {
  getField,
  token: state => state.token
};

const actions = {
  setUser({ commit }, user) {
    commit("setToken", user.token);
    commit("setExpiration", user.expiresIn);
    commit("setUserId", user.id);
    commit("setStatus", user.status);
    commit("setRole", user.role);
  },
  setOtpChallenge({ commit }, otpChallenge) {
    commit("setOtpChallenge", otpChallenge);
  },
  setOtpChallengeStatus({ commit }, status) {
    commit("setOtpChallengeStatus", status);
  }
};

const mutations = {
  updateField,
  setExpiration(state, expiresIn) {
    const date = new Date();
    const epireAt = date.setSeconds(date.getSeconds() + expiresIn);
    state.expiresIn = epireAt;
    localStorage.expiresIn = epireAt;
  },
  addImage: (state, item) => {
    state.images.push(item);
    localStorage.images
      ? localStorage.images.push(item)
      : (localStorage.images = item);
  },
  removeImage: (state, id) => {
    state.images = state.images.filter(item => item.id !== id);
  },
  setToken(state, token) {
    state.token = token;
    localStorage.token = token;
    axios.defaults.headers.common["X-session-token"] = token;
  },
  setIsOtpPopUpOpen(state, isOtpPopUpOpen) {
    state.isOtpPopUpOpen = isOtpPopUpOpen;
  },
  setisOtpSuccessful(state, isOtpSuccessful) {
    state.isOtpSuccessful = isOtpSuccessful;
  },
  setUserId(state, id) {
    state.id = id;
    localStorage.id = id;
  },
  setStatus(state, status) {
    state.status = status;
    localStorage.status = status;
  },
  setRole(state, role) {
    state.role = role;
    localStorage.role = role;
  },
  logout(state) {
    state.token = "";
    localStorage.clear();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
