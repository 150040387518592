<template>
  <v-dialog
    :value="isOpen"
    :title="$t('common.confirm')"
    maxWidth="400"
    :persistent="true"
    @keydown.esc="$emit('esc-key-down', $event)"
  >
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">{{
          $t("common.confirm")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="text" class="my-4 px-4 pb-0">
        {{ $t("common.confirmText") }} {{ text }}
        <slot></slot>
      </v-card-text>
      <v-divider class="mt-0"></v-divider>
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
        <v-btn @click="cancel" color="grey darken-1" text>
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn @click="confirmed" color="primary" text>
          {{ $t("common.yes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    isOpen: Boolean,
    text: String
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    confirmed() {
      this.$emit("confirmed");
    }
  }
};
</script>
