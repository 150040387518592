import axios from "../../shared/axios";
import i18n from "../../plugins/i18n";
import store from "../../store/store";
import { get } from "lodash";

const request = async function(
  options,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const headers = { ...(options.headers || {}), "X-language-key": i18n.locale };
  if (store.getters.token) headers["X-session-token"] = store.getters.token;
  else headers["X-session-token"] = localStorage.token;
  options.headers = headers;
  try {
    let response = await axios(options);
    successCallback(response.data);
    return Promise.resolve(response.data);
  } catch (error) {
    const responseMessage = get(error, "response.data.message");
    const responseCode = get(error, "response.data.code");
    if (responseCode === "1000") {
      store.commit("auth/setIsOtpPopUpOpen", true);
    } else if (responseMessage === "OTP is wrong or expired") {
      store.commit("auth/setIsOtpSuccessful", false);
    } else if (
      responseMessage === "Session Expired!" ||
      responseMessage === "انتهت مدة الجلسة!"
    ) {
      this.$router.go(0);
      location.reload();
    }
    //OTP is wrong or expired
    if (responseMessage !== undefined)
      store.dispatch("main/setError", {
        message: ` ${responseMessage === undefined ? "" : responseMessage}`
      });
    errorCallback(error);
    return Promise.reject(error);
  }
};

export default request;
