import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg" || "fas"
  },
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: "#7f0000",
        secondary: "#f05545",
        accent: colors.shades.black,
        error: "#6A0102"
      },
      dark: {
        primary: "#7f0000"
      }
    }
  }
});
