<template>
  <div>
    <v-container>
      <v-data-iterator
        :items="filteredOrders"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        hide-default-footer
        :loading="loadingOrders"
      >
        <template v-slot:no-results>
          <v-row justify="center" align="center">
            <v-card-title class="mt-12 text--center">
              {{ $t("common.noBooking") }}
            </v-card-title>
          </v-row>

          <v-row
            v-if="role === 'USER'"
            class="mt-8"
            justify="center"
            align="center"
            row
          >
            <v-btn
              :to="{ name: 'dishes' }"
              class="my-8 font-weight-bold"
              x-large
              color="white"
              >{{ $t("common.add") + " " + $t("appHeader.tabs.dishes") }}
              <v-icon class="mx-1" color="primary">fi fi-rr-utensils</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-row justify="center" align="center">
            <v-card-title class="my-12 text--center">
              {{ $t("common.noBooking") }}
            </v-card-title>
          </v-row>

          <v-row
            class="mb-8"
            v-if="role === 'USER'"
            justify="center"
            align="center"
            row
          >
            <v-btn
              :to="{ name: 'dishes' }"
              class="my-8 font-weight-bold"
              x-large
              color="white"
              >{{ $t("common.add") + " " + $t("appHeader.tabs.dishes") }}
              <v-icon class="mx-1" color="primary">fi fi-rr-utensils</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:header>
          <v-toolbar
            dense
            height="auto"
            :dark="false"
            rounded
            class="mb-1 px-lg-3"
          >
            <v-row row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="fi fi-rr-search"
                  :label="$t('common.search')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  clearable
                  v-model="sortByStatus"
                  flat
                  solo-inverted
                  hide-details
                  :items="keys"
                  prepend-inner-icon="fi fi-rr-filter"
                  :label="$t('common.sortByStatus')"
                ></v-select>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col class="px-0 mx-0" cols="12">
              <v-expansion-panels :value="opened" popout>
                <v-expansion-panel
                  @change="
                    getChefNameAndDishes(item.chef, item.dishes, item.id)
                  "
                  v-for="(item, i) in props.items"
                  :key="i"
                >
                  <v-expansion-panel-header class="pa-0 px-4 my-2">
                    <v-row row class="pa-0 ma-0">
                      <v-col class="ma-0 pa-0" cols="5" md="2" lg="2" xl="2">
                        <v-card
                          color="primary"
                          class="text-center pt-2"
                          height="100%"
                          width="100%"
                          :style="
                            $route.params.lang === 'en'
                              ? 'border-radius: 10px 0px 0px 10px;'
                              : 'border-radius: 0px 10px 10px 0px;'
                          "
                        >
                          <v-card-subtitle class="font-weight-bold white--text">
                            {{ getStatusText(item.status) }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col class="ma-0 pa-0" cols="7" md="10" lg="10" xl="10">
                        <v-stepper
                          class="elevation-0"
                          style="border-radius: 0px 5px 5px 0px;"
                          :value="selectedStep(item.status)"
                        >
                          <v-stepper-header>
                            <v-stepper-step
                              @click="
                                opened === i ? (opened = null) : (opened = i)
                              "
                              :rules="[() => item.status != 'REJECTED']"
                              step="1"
                              :complete="item.status != 'PENDING'"
                              error-icon="fas fa-times"
                            >
                              {{ $t("common.checkout") }}
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                              @click="
                                opened === i ? (opened = null) : (opened = i)
                              "
                              step="2"
                              :complete="
                                item.status == 'PAID' ||
                                  item.status == 'DELIVERED' ||
                                  item.status == 'RATED'
                              "
                            >
                              {{ $t("orders.payment") }}
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step
                              @click="
                                opened === i ? (opened = null) : (opened = i)
                              "
                              :step="'3' || '4'"
                              :complete="
                                item.status == 'RATED' ||
                                  item.status == 'DELIVERED' ||
                                  item.status == 'RATED'
                              "
                            >
                              {{ $t("orders.receipt") }}
                            </v-stepper-step>
                          </v-stepper-header>
                        </v-stepper>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-lg-12">
                    <template v-if="!url">
                      <v-card-subtitle class="text-center pt-0"
                        >{{ $t("orders.id") }}

                        <u>{{ item.id }}</u>
                      </v-card-subtitle>

                      <v-simple-table
                        :class="
                          !$vuetify.breakpoint.mdAndUp
                            ? 'px-lg-12 mx-lg-12 text-start'
                            : 'px-lg-12 mx-lg-12 text-center'
                        "
                      >
                        <template v-slot:default>
                          <tbody>
                            <tr v-if="role === 'USER'">
                              <td>{{ $t("common.chef") }}</td>
                              <td>
                                <v-skeleton-loader
                                  v-if="loadingChefName"
                                  width="auto"
                                  type="heading"
                                ></v-skeleton-loader>
                                {{ chefName }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ $t("common.order") }}
                              </td>

                              <td>
                                <v-skeleton-loader
                                  v-if="loadingDishes"
                                  width="auto"
                                  type="list-item-avatar"
                                ></v-skeleton-loader>
                                <v-simple-table>
                                  <template v-slot:default>
                                    <tbody>
                                      <tr
                                        v-for="dish in dishes &&
                                        dishes.length > 0
                                          ? dishes
                                          : item.dishes"
                                        :key="dish.id"
                                        @click="dishView(dish)"
                                        :style="
                                          dishes && dishes.length > 0
                                            ? 'cursor: pointer;'
                                            : ''
                                        "
                                      >
                                        <td>
                                          {{ dish.selectedNumberOfPersons }}
                                          <v-icon x-small>fas fa-users</v-icon>
                                        </td>
                                        <td>
                                          <v-avatar class="mx-4 my-2" size="35">
                                            <img
                                              v-if="
                                                dish.imgs &&
                                                  dish.imgs.length > 0
                                              "
                                              :src="imgUrl(dish.imgs[0])"
                                              alt="Dish"
                                            />
                                          </v-avatar>
                                        </td>
                                        <td
                                          :align="
                                            $route.params.lang === 'en'
                                              ? 'left'
                                              : 'right'
                                          "
                                        >
                                          {{ dish.name }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ $t("common.date") }}/{{ $t("common.time") }}
                              </td>
                              <td>{{ item.date }} / {{ item.time }}</td>
                            </tr>

                            <tr
                              v-if="
                                item.status !== 'RATED' &&
                                  item.status !== 'DELIVERED'
                              "
                            >
                              <td>{{ $t("common.location") }}</td>
                              <td>
                                <a
                                  :href="
                                    `https://maps.google.com/maps?z=12&t=m&q=${item.where.location.latitude}+${item.where.location.longitude}`
                                  "
                                  target="#"
                                  >{{ item.where.geocode }}</a
                                >
                              </td>
                            </tr>
                            <tr v-if="mobile">
                              <td>{{ $t("common.mobile") }}</td>
                              <td>{{ mobile }}</td>
                            </tr>
                            <tr v-if="item.notes">
                              <td>{{ $t("common.notes") }}</td>
                              <td>{{ item.notes }}</td>
                            </tr>

                            <tr>
                              <td>{{ $t("checkout.total") }}</td>
                              <td class="font-weight-bold">
                                {{ item.total }}
                                {{ $t("common.currency") }}
                              </td>
                            </tr>

                            <tr
                              v-if="
                                item.status !== 'RATED' &&
                                  item.status !== 'DELIVERED' &&
                                  !url
                              "
                            >
                              <td>{{ $t("dish.requiredMaterials") }}</td>
                              <td>
                                <v-row
                                  class="mx-lg-12 text-center px-lg-12"
                                  justify="center"
                                  align="center"
                                  row
                                >
                                  <v-col cols="12" class="ma-0 pb-0">
                                    <v-card class="elevation-0 mb-1">
                                      <v-row
                                        justify="center"
                                        align="center"
                                        class="pa-0 ma-0 mx-1"
                                      >
                                        <v-col class="ma-0 pa-0" cols="11">
                                          <v-chip-group
                                            :column="show"
                                            mandatory
                                            show-arrows
                                          >
                                            <v-chip
                                              outlined
                                              small
                                              :class="
                                                !show ? ' px-1' : ' px-1 ma-2'
                                              "
                                              v-for="(requiredMaterial,
                                              index) in filterRequiredMaterials(
                                                item.requiredMaterials
                                              )"
                                              :key="index"
                                            >
                                              <v-avatar
                                                class="white--text primary font-weight-bold"
                                                rounded
                                                left
                                              >
                                                {{ requiredMaterial.quantity }}
                                              </v-avatar>
                                              <b>
                                                {{
                                                  getMaterialsText(
                                                    requiredMaterial
                                                  )
                                                }}
                                              </b>
                                            </v-chip>
                                          </v-chip-group>
                                        </v-col>
                                        <v-col
                                          v-if="
                                            filterRequiredMaterials(
                                              item.requiredMaterials
                                            ).length > 3
                                          "
                                          class="ma-0 pa-0"
                                          cols="1"
                                          xl="1"
                                          lg="1"
                                          md="1"
                                        >
                                          <v-btn
                                            block
                                            class="ma-0 pa-0 elevation-0"
                                            @click="show = !show"
                                            solo
                                          >
                                            <v-icon
                                              color="primary"
                                              v-if="!show"
                                              class="pa-0"
                                              >fas fa-arrow-down</v-icon
                                            >
                                            <v-icon
                                              color="primary"
                                              v-else
                                              class="pa-0"
                                              >fas fa-arrow-up</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                    <v-stepper :value="selectedStep(item.status)">
                      <v-stepper-items>
                        <v-stepper-content class="pt-2" step="1">
                          <v-card-actions
                            v-if="role === 'CHEF' && item.status === 'PENDING'"
                            class="justify-center py-0 mt-2"
                          >
                            <v-btn
                              class="mx-lg-2"
                              :loading="loadingAction"
                              @click="isConfirmDialogOpen = true"
                              color="primary"
                              large
                            >
                              {{ $t("common.reject") }}
                            </v-btn>
                            <ConfirmDialog
                              @canceled="isConfirmDialogOpen = false"
                              @confirmed="reject(item.id)"
                              :isOpen="isConfirmDialogOpen"
                              :text="' ' + $t('warning.rejectOrder')"
                            />
                            <v-btn
                              :loading="loadingAction"
                              @click="accept(item.id)"
                              color="green"
                              class="white--text mx-2"
                              large
                            >
                              {{ $t("common.accept") }}
                            </v-btn>
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content class="pt-2 text-center" step="2">
                          <v-btn
                            :loading="loadingAction"
                            v-if="!url && role === 'USER'"
                            @click="pay(item.id, true)"
                            large
                            class="justify-center"
                            color="primary"
                            >{{ $t("common.pay") }}</v-btn
                          >
                          <v-btn
                            :loading="loadingAction"
                            v-if="!url && role === 'USER' && isSafari"
                            @click="pay(item.id, false)"
                            large
                            class="justify-center mx-4  font-weight-bold"
                            outlined
                          >
                            <v-icon class="mx-2">fa-brands fa-apple</v-icon>
                            {{ $t("common.apple") }}
                          </v-btn>
                          <iframe
                            title="frame"
                            v-if="url"
                            :ref="url"
                            :src="url"
                            style="width:100%;height:700px;"
                          ></iframe>
                        </v-stepper-content>
                        <v-stepper-content class="pt-2 text-center" step="3">
                          <v-btn
                            :loading="loadingAction"
                            v-if="item.status === 'PAID' && role === 'CHEF'"
                            @click="deliver(item.id)"
                            large
                            class="justify-center"
                            color="primary"
                            >{{ $t("common.deliver") }}</v-btn
                          >
                        </v-stepper-content>
                        <v-stepper-content class="pt-2 text-center" step="4">
                          <v-btn
                            :loading="loadingAction"
                            v-if="!url && role === 'USER'"
                            @click="rateOpen"
                            large
                            class="justify-center"
                            color="primary"
                            >{{ $t("common.rate") }}</v-btn
                          >
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                    <RateForm
                      :orderId="item.id"
                      :isOpen="rate"
                      :chefName="chefName"
                      :dishes="dishes"
                      @close-popup="rated"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </template>

        <template
          v-slot:footer
          v-if="numberOfPages !== 0 && orders && orders.length > 4"
        >
          <v-row class="mt-2 mx-1" align="center" justify="center">
            <span class="grey--text">{{ $t("common.ItemsPerPage") }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon small>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <div v-if="numberOfPages > 1">
              <span
                class="mx-2
            grey--text"
              >
                {{ page }} / {{ numberOfPages }}
              </span>
              <v-btn
                v-if="page > 1"
                small
                fab
                dark
                color="primary"
                class="mr-1"
                @click="formerPage"
              >
                <v-icon v-if="$route.params.lang === 'en'"
                  >fas fa-arrow-left</v-icon
                >
                <v-icon v-else>fas fa-arrow-right</v-icon>
              </v-btn>
              <v-btn
                v-if="page !== numberOfPages"
                small
                fab
                dark
                color="primary"
                class="ml-1"
                @click="nextPage"
              >
                <v-icon v-if="$route.params.lang !== 'en'"
                  >fas fa-arrow-left</v-icon
                >
                <v-icon v-else>fas fa-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import OrderService from "@/services/api/OrderService";
import ChefService from "@/services/api/ChefService";
import DishService from "@/services/api/DishService";
import RateForm from "@/components/RateForm";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "Orders",
  components: { RateForm, ConfirmDialog },
  data() {
    return {
      isConfirmDialogOpen: false,
      search: "",
      url: null,
      orders: [],
      chefName: "",
      mobile: "",
      dishes: null,
      itemsPerPage: 4,
      itemsPerPageArray: [2, 4, 8, 10],
      page: 1,
      sortByStatus: "",
      loadingOrders: false,
      loadingAction: false,
      loadingChefName: false,
      loadingDishes: false,
      loading: false,
      keys: this.$t("lists.status"),
      rate: false,
      show: false,
      opened: null
    };
  },
  computed: {
    ...mapFields("auth", ["role", "token", "expiresIn"]),
    ...mapFields("main", ["profileDrawer"]),
    ...mapFields("main", ["materials"]),
    numberOfPages() {
      return this.orders
        ? Math.ceil(this.filteredOrders.length / this.itemsPerPage)
        : 0;
    },
    filteredOrders() {
      return this.orders
        ? this.sortByStatus
          ? this.orders.filter(item => item.status.includes(this.sortByStatus))
          : this.orders
        : [];
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
  },
  methods: {
    rateOpen() {
      this.loadingAction = true;
      this.rate = true;
      this.getOrders();
    },
    rated() {
      this.rate = false;
      this.getOrders();
      this.loadingAction = false;
    },
    getStatusText(value) {
      return this.$t("lists.status")
        .filter(day => day.value === value)
        .map(x => x.text)[0];
    },
    dishView(dish) {
      if (this.dishes && this.dishes.length > 0) {
        this.$router.push({
          name: "dish",
          params: {
            dish: `${dish.name
              .split(" ")
              .map(name => name.toLowerCase())
              .filter(name => name !== " ")
              .join("-")}-${dish.id}`
          }
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.$router.go(0);
      }
    },
    imgUrl(img) {
      return `${process.env.VUE_APP_BACKEND_URL}/images/${img}`;
    },
    selectedNumberOfPersons(item, dish) {
      return item && dish
        ? item.dishes.filter(id => dish.id === id.id)[0].selectedNumberOfPersons
        : "";
    },
    getChefNameAndDishes(id, dishes, orderId) {
      this.mobile = "";
      this.chefName = "";
      this.dishes = [];
      this.chefNameById(id);
      this.dishesByIds(dishes);
      this.orders.filter(
        order => order.status === "PAID" && order.id === orderId
      ).length > 0
        ? this.userMobile(orderId)
        : "";
    },
    async pay(id, framed) {
      const formData = new FormData();
      formData.append("orderId", id);
      formData.append("framed", framed);
      this.loadingAction = true;
      try {
        const url = await OrderService.pay(formData);
        framed === true
          ? (this.url = url.url)
          : window.location.replace(url.url);
      } catch {
        this.sortByStatus = "";
        this.getOrders();
      } finally {
        this.loadingAction = false;
        this.getOrders();
      }
    },
    async accept(id) {
      const formData = new FormData();
      formData.append("orderId", id);
      try {
        this.loadingAction = true;
        await OrderService.accept(formData);
        this.getOrders();
        this.$root.$alert.success({
          message: this.$t("success.orderAccept")
        });
      } catch {
        this.sortByStatus = "";
        this.getOrders();
        this.loadingAction = false;
      }
    },
    async deliver(id) {
      const formData = new FormData();
      formData.append("orderId", id);
      try {
        this.loadingAction = true;
        await OrderService.delivered(formData);
        this.getOrders();
        this.$root.$alert.success({
          message: this.$t("success.orderDelivered")
        });
      } catch {
        this.sortByStatus = "";
        this.getOrders();
      } finally {
        this.getOrders();
        this.loadingAction = false;
      }
    },
    async reject(id) {
      this.isConfirmDialogOpen = false;
      const formData = new FormData();
      formData.append("orderId", id);
      try {
        this.loadingAction = true;
        await OrderService.reject(formData);
        this.getOrders();

        this.$root.$alert.success({
          message: this.$t("success.orderReject")
        });
      } catch {
        this.sortByStatus = "";
        this.getOrders();
        this.loadingAction = false;
      }
    },
    selectedStep(status) {
      switch (status) {
        case "PENDING" || "REJECTED":
          return 1;
        case "ACCEPTED":
          return 2;
        case "PAID":
          return 3;
        case "DELIVERED" || "RATED":
          return 4;
        default:
          return 1;
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async getOrders() {
      try {
        const { orders } = await OrderService.getOrders();
        this.orders = orders;
      } catch {
      } finally {
        this.loadingAction = false;
      }
    },
    async chefNameById(id) {
      try {
        this.loadingChefName = true;
        const name = await ChefService.getChefNameById(id);
        this.chefName = name;
      } catch {
      } finally {
        this.loadingChefName = false;
      }
    },
    async userMobile(id) {
      try {
        const { mobile } = await OrderService.getUserMobile(id);
        this.mobile = mobile;
      } catch {}
    },
    getMaterialsText(item) {
      return this.materials
        .filter(material => material.value === item.value)
        .map(material => material.text)[0];
    },
    filterRequiredMaterials(mats) {
      return mats.filter(mat => {
        if (this.getMaterialsText(mat) !== undefined) return mat;
      });
    },
    async dishesByIds(ids) {
      const formData = new FormData();
      formData.append("ids", JSON.stringify(ids.map(ids => ids.id)));
      try {
        this.loadingDishes = true;
        const dishes = await DishService.getDishesByIds(formData);
        this.dishes = dishes.dishes.map(dish => {
          return {
            ...dish,
            selectedNumberOfPersons: ids.filter(item => item.id === dish.id)[0]
              .selectedNumberOfPersons
          };
        });
      } catch {
      } finally {
        this.loadingDishes = false;
      }
    }
  },
  mounted() {
    if (this.token && new Date() < this.expiresIn) {
      this.$route.params.order ? (this.search = this.$route.params.order) : "";
      this.getOrders();
    } else {
      this.$root.$alert.info({
        title: this.$t("error.auth")
      });
      this.profileDrawer = true;
    }
  }
};
</script>
