<template>
  <CardsList
    :loading="loading"
    type="chef"
    :items="chefs"
    :itemsPerPage="itemsPerPage"
    @update-items-per-page="updateItemsPerPage"
  />
</template>

<script>
import CardsList from "@/components/CardsList.vue";
import ChefService from "@/services/api/ChefService";

export default {
  components: { CardsList },
  data() {
    return {
      loading: false,
      chefs: [],
      itemsPerPage: 8
    };
  },
  methods: {
    async getChefs() {
      this.loading = true;
      try {
        const chefs = await ChefService.getChefs();
        this.chefs = chefs.chefs;
      } catch {
      } finally {
        this.loading = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    }
  },
  mounted() {
    this.getChefs();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>
