<template>
  <v-container class="mt-3">
    <v-sheet
      v-if="dishLoading"
      height="100%"
      width="100%"
      :color="'grey lighten-4'"
      class="pa-3"
    >
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-skeleton-loader
            :boilerplate="true"
            height="100%"
            type="image"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-skeleton-loader
            :boilerplate="true"
            height="100%"
            type="article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-sheet>

    <DishCard
      v-else
      :loading="dishLoading"
      :hover="true"
      :expandCard="true"
      :dish="dish"
    />
    <v-card-title
      v-if="dishes.length > 0"
      class="pb-0 pt-2 mt-2 px-1 subtitle-1"
    >
      {{ $t("common.moreByChef") }}
      <v-btn
        :to="dish.chef ? { name: 'chef', params: { chef: dish.chef } } : ''"
        text
        color="primary"
        class="px-0 mx-0"
        ><h3>
          <u>{{ this.chefName }}</u>
        </h3></v-btn
      >
    </v-card-title>

    <v-sheet
      v-if="!$vuetify.breakpoint.mdAndUp"
      width="auto"
      class="mx-0 px-0"
      elevation="0"
    >
      <v-slide-group class="pa-0">
        <v-slide-item
          class="mb-1"
          v-for="dish in dishes.slice(0, 6)"
          :key="dish.name"
        >
          <v-card class="ma-0 pa-0 mx-2  elevation-0" width="auto">
            <DishCard
              class="elevation-1"
              style="width: 15em;"
              :expandCard="false"
              :dish="dish"
            />
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <CardsList
      v-if="$vuetify.breakpoint.mdAndUp && dishes.length > 0"
      type="dish"
      :withHeader="false"
      :itemsPerPage="itemsPerPage"
      @update-items-per-page="updateItemsPerPage"
      :items="dishes"
      :loading="dishLoading"
    />

    <div v-show="dishesByCategories.length > 0">
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-divider class="mt-4"></v-divider>
        <v-card-title class="pb-0 pt-2">
          {{ $t("common.similar") }}
        </v-card-title>
        <CardsList
          type="dish"
          :withHeader="false"
          :itemsPerPage="itemsPerPage"
          @update-items-per-page="updateItemsPerPage"
          :items="dishesByCategories"
        />
      </div>
      <div v-else>
        <v-card-title
          v-if="dishesByCategories"
          class="pb-0 pt-2 mt-8 px-1 subtitle-1"
        >
          {{ $t("common.similar") }}
        </v-card-title>
        <v-sheet width="auto" class="mx-0 px-0" elevation="0">
          <v-slide-group class="pa-0">
            <v-slide-item
              class="mb-2"
              v-for="dish in dishesByCategories.slice(0, 6)"
              :key="dish.name"
            >
              <v-card class="ma-0 pa-0 mx-2 elevation-0" width="auto">
                <DishCard
                  class="elevation-1"
                  style="width: 15em;"
                  :expandCard="false"
                  :dish="dish"
                />
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>
    </div>
  </v-container>
</template>
<script>
import DishCard from "@/components/DishCard.vue";
import CardsList from "@/components/CardsList.vue";
import DishService from "@/services/api/DishService";
import ChefService from "@/services/api/ChefService";

export default {
  name: "Dish",
  components: { DishCard, CardsList },
  data() {
    return {
      dish: {},
      dishes: [],
      dishesByCategories: [],
      chefName: "",
      dishLoading: false,
      itemsPerPage: 8
    };
  },
  methods: {
    async getDishById(id) {
      this.dishLoading = true;
      try {
        const dish = await DishService.getDishById(id);
        this.dish = dish;
        this.getDishesByChefId(this.dish.chef);
        this.getDishesByCategories(this.dish.categories);
        this.getChefNameById(this.dish.chef);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch {
        this.$router.push({ name: "home" });
      } finally {
        this.dishLoading = false;
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async getDishesByChefId(id) {
      this.loading = true;
      try {
        const dishes = await DishService.getDishesByChefId(id);
        this.dishes = dishes.dishes;
        this.dishes = this.dishes.filter(dish => dish.id !== this.dish.id);
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async getDishesByCategories(categs) {
      const categories = categs.map(category => category).join("-");
      this.dishLoading = true;
      try {
        const dishes = await DishService.getDishesByCategories(categories);
        this.dishesByCategories = dishes.dishes.filter(
          dish => dish.chef !== this.dish.chef
        );
      } catch {
      } finally {
        this.dishLoading = false;
      }
    },
    async getChefNameById(id) {
      this.chefLoading = true;
      try {
        const name = await ChefService.getChefNameById(id);
        this.chefName = name;
      } catch {
      } finally {
        this.chefLoading = false;
      }
    }
  },
  mounted() {
    this.getDishById(this.$route.params.dish);
  }
};
</script>
<style scoped>
.chef {
  color: primary;
}

.v-slide-group__wrapper {
  overflow-x: auto; /* Enables the horizontal scrollbar */
  /* Next lines hides scrollbar in different browsers for styling purposes */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.v-slide-group__wrapper::-webkit-scrollbar {
  display: none; /* Chrome opera and Safary */
  width: 0px;
  background: transparent;
}
</style>
