<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card>
      <v-toolbar dark>
        <v-toolbar-items>
          <v-btn dark text @click="$refs.cropper.rotate(-90)"
            ><v-icon>fa-rotate-left</v-icon></v-btn
          >
          <v-btn dark text @click="$refs.cropper.rotate(90)"
            ><v-icon>fa-rotate-right</v-icon></v-btn
          >
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn class="mx-3" text icon dark @click="isConfirmDialogOpen = true">
          <b> {{ $t("common.cancel") }}</b>
        </v-btn>
        <v-btn
          large
          class="mx-3"
          color="primary"
          elevation="5"
          @click="saveCropButton()"
        >
          <b> {{ $t("common.save") }}</b>
        </v-btn>
      </v-toolbar>

      <v-container class="mx-auto pb-0 pt-0">
        <v-row row class="py-0 mt-0">
          <v-col justify="center" align="center" cols="12" class="pt-0 mt-0">
            <vue-cropper
              id="cropper"
              class="my-1"
              ref="cropper"
              :view-mode="1"
              :background="false"
              :rotatable="true"
              :draggable="false"
              :src="image"
              alt="Source Image"
              :modal="true"
              :center="true"
              :highlight="true"
              :zoomable="true"
              :ready="onCropperReady"
              :cropmove="updateCropSizeTooltip"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <ConfirmDialog
      @canceled="isConfirmDialogOpen = false"
      @confirmed="cancelCropButton"
      :isOpen="isConfirmDialogOpen"
      :text="$t('warning.cancelUplaod')"
    />
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  props: {
    value: Boolean,
    image: String,
    type: { type: String, default: "IMAGE" }
  },
  components: {
    VueCropper,
    ConfirmDialog
  },
  data() {
    return {
      isConfirmDialogOpen: false
    };
  },
  methods: {
    onCropperReady() {
      const cropBox = document.getElementsByClassName("cropper-crop-box")[0];
      const cropSizeTooltip = document.createElement("span");
      cropSizeTooltip.className = "crop-size-tooltip";
      const emptyTextNode = document.createTextNode("");
      cropSizeTooltip.appendChild(emptyTextNode);
      cropBox.appendChild(cropSizeTooltip);
      this.cropSizeTooltip = cropSizeTooltip;
      this.updateCropSizeTooltip();
    },
    updateCropSizeTooltip() {
      const { width, height } = this.$refs.cropper.getCroppedCanvas();
      this.cropSizeTooltip.innerText = `${width}x${height}px`;
    },
    saveCropButton() {
      const { width, height } = this.$refs.cropper.getCroppedCanvas();
      if (width < 500 || height < 500) {
        this.$root.$alert.error({
          title: `${this.$t("error.dimensions")} ${width} x ${height} ${this.$t(
            "error.dimensionsMustBe"
          )}`
        });
      } else {
        this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
          const formData = new FormData();
          formData.append("file", blob, this.type);
          this.$emit("upload-image", formData);
        }, `image/webp`);
      }
    },
    cancelCropButton() {
      this.isConfirmDialogOpen = false;
      this.$emit("cancel-crop");
    },
    reset() {
      this.$refs.cropper.reset();
    },
    replace(image) {
      this.$refs.cropper.replace(image);
    }
  }
};
</script>

<style lang="scss">
#cropper {
  height: 80vh;
}
.cropper-view-box {
  outline-color: primary;
  width: 100%;
  height: 100%;
}
.cropper-line {
  background-color: primary;
}
.cropper-point {
  background-color: primary;
}
.crop-size-tooltip {
  color: red;
  font-size: 13px;
  top: -8px;
  right: 0;
  position: absolute;
}
</style>
