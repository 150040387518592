<template>
  <Dialog
    :value="value"
    maxWidth="800"
    :title="$t('chef.signUpForm.chefSignUp')"
    :rightBtnText="$t('common.signUp')"
    @right-btn-click="chefSignUp"
    :leftBtnText="$t('common.cancel')"
    @left-btn-click="clearForm"
    :persistent="true"
    :loading="uploading"
  >
    <template>
      <v-container>
        <form ref="signupForm" class="mx-lg-12">
          <v-text-field
            outlined
            dense
            v-model="chef.en.firstName"
            :error-messages="firstNameErrorsEN"
            @keypress="allowedChar"
            maxLength="50"
            :label="$t('chef.signUpForm.firstNameEN')"
            required
            @input="$v.chef.en.firstName.$touch()"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            v-model="chef.en.lastName"
            :error-messages="lastNameErrorsEN"
            maxLength="50"
            :label="$t('chef.signUpForm.lastNameEN')"
            required
            @input="$v.chef.en.lastName.$touch()"
            @keypress="allowedChar"
          ></v-text-field>

          <v-row class="pa-0">
            <v-col class="my-0 py-0" cols="12" md="8" lg="8" xl="8">
              <v-text-field
                outlined
                dense
                v-model="chef.idNumber"
                :error-messages="idNumberErrors"
                type="text"
                pattern="\d*"
                maxLength="10"
                :label="$t('chef.signUpForm.nationalOrIqamaNumber')"
                required
                @input="$v.chef.idNumber.$touch()"
                @keypress="onlyNumbers"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="4" lg="4" xl="4">
              <v-dialog
                ref="dateDialog"
                v-model="dateDialog"
                :return-value.sync="chef.idExpiry"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="chef.idExpiry"
                    :error-messages="idExpiryErrors"
                    :label="$t('chef.signUpForm.idExpiry')"
                    required
                    @input="$v.chef.idExpiry.$touch()"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="chef.idExpiry"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().getTime() + 86400000 * 30 * 12 * 10)
                      .toISOString()
                      .substr(0, 10)
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="gray" @click="dateDialog = false">
                    {{ $t("common.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateDialog.save(chef.idExpiry)"
                  >
                    {{ $t("common.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-text-field
            outlined
            dense
            v-model="chef.mobile"
            @input="$v.chef.mobile.$touch()"
            @keypress="onlyNumbers"
            placeholder="966XXXXXXXXX"
            type="text"
            pattern="\d*"
            :error-messages="mobileErrors"
            maxLength="12"
            :label="$t('common.mobile')"
            required
          ></v-text-field>
          <v-text-field
            outlined
            dense
            v-model="chef.email"
            :error-messages="emailErrors"
            maxLength="128"
            :label="$t('common.email')"
            required
            @input="$v.chef.email.$touch()"
            @blur="$v.chef.email.$touch()"
          ></v-text-field>
          <v-select
            v-model="chef.city"
            :items="cities"
            outlined
            dense
            required
            :error-messages="cityErrors"
            :label="$t('common.city')"
          >
          </v-select>

          <v-text-field
            outlined
            dense
            v-model="chef.password"
            :label="$t('common.password')"
            type="password"
            :error-messages="passwordErrors"
            @blur="$v.chef.password.$touch()"
            @change="$v.chef.password.$touch()"
            :hint="$t('common.passwordHint')"
          ></v-text-field>
          <v-text-field
            class="mb-0"
            outlined
            dense
            :error-messages="passwordConfirmErrors"
            v-model="chef.passwordConfirm"
            :label="$t('common.passwordConfirm')"
            type="password"
            @change="$v.chef.passwordConfirm.$touch()"
            @blur="$v.chef.passwordConfirm.$touch()"
          ></v-text-field>
          <div class="d-flex flex-inline">
            <v-checkbox
              v-model="chef.agreeToTermsAndConditions"
              required
              :error-messages="agreeToTermsAndConditionsErrors"
              @change="$v.chef.agreeToTermsAndConditions.$touch()"
              @blur="$v.chef.agreeToTermsAndConditions.$touch()"
            >
            </v-checkbox>
            <v-card-subtitle>
              {{ this.$t("common.agreeTo") }}
              <v-btn
                target="_blank"
                :to="{ name: 'terms-and-conditions' }"
                class="px-1"
                color="primary"
                text
              >
                <u>{{ this.$t("common.termsAndConditionsOnly") }}</u>
              </v-btn>
            </v-card-subtitle>
          </div>
        </form>
      </v-container>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/Dialog";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  numeric
} from "vuelidate/lib/validators";
import AuthService from "@/services/api/AuthService";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ChefSignUpForm",
  mixins: [validationMixin],
  components: {
    Dialog
  },
  data() {
    return {
      dateDialog: false,
      value: false,
      uploading: false,
      cities: this.$t("lists.cities"),
      chef: {
        en: {
          firstName: "",
          lastName: ""
        },
        ar: {
          firstName: "",
          lastName: ""
        },
        idNumber: "",
        idExpiry: "",
        mobile: "",
        email: "",
        city: "",
        password: "",
        passwordConfirm: "",
        agreeToTermsAndConditions: false
      }
    };
  },
  computed: {
    ...mapFields("auth", ["isOtpPopUpOpen", "mobile", "role"]),
    firstNameErrorsEN() {
      const errors = [];
      if (!this.$v.chef.en.firstName.$dirty) return errors;
      !this.$v.chef.en.firstName.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.chef.en.firstName.nameValid &&
        errors.push(
          this.$t("error.isRequired") +
            " " +
            this.$t("chef.signUpForm.firstNameEN")
        );
      return errors;
    },
    lastNameErrorsEN() {
      const errors = [];
      if (!this.$v.chef.en.lastName.$dirty) return errors;
      !this.$v.chef.en.lastName.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.chef.en.lastName.nameValid &&
        errors.push(
          this.$t("error.isRequired") +
            " " +
            this.$t("chef.signUpForm.lastNameEN")
        );
      return errors;
    },
    idNumberErrors() {
      const errors = [];
      if (!this.$v.chef.idNumber.$dirty) return errors;
      !this.$v.chef.idNumber.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.chef.idNumber.idValid && errors.push(this.$t("error.id"));
      return errors;
    },
    idExpiryErrors() {
      const errors = [];
      if (!this.$v.chef.idExpiry.$dirty) return errors;
      !this.$v.chef.idExpiry.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.chef.mobile.$dirty) return errors;
      !this.$v.chef.mobile.required && errors.push(this.$t("error.isRequired"));
      !this.$v.chef.mobile.numeric && errors.push(this.$t("error.number"));
      !this.$v.chef.mobile.minLength && errors.push(this.$t("error.mobile"));
      !this.$v.chef.mobile.mobileValid && errors.push(this.$t("error.mobile"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.chef.email.$dirty) return errors;
      !this.$v.chef.email.email && errors.push(this.$t("error.validEmail"));
      !this.$v.chef.email.required && errors.push(this.$t("error.isRequired"));
      !/^[a-zA-Z0-9_+&*-]+(?:.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+.)+[a-zA-Z]{2,}$/.test(
        this.chef.email
      ) && errors.push(this.$t("error.validEmail"));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.chef.city.$dirty) return errors;
      !this.$v.chef.city.required && errors.push(this.$t("error.isRequired"));
      return errors;
    },
    agreeToTermsAndConditionsErrors() {
      const errors = [];
      if (!this.$v.chef.agreeToTermsAndConditions.$dirty) return errors;
      !this.$v.chef.agreeToTermsAndConditions.checked &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.chef.password.$dirty) return errors;
      !this.$v.chef.password.required &&
        errors.push(this.$t("error.isRequired"));
      !this.$v.chef.password.minLength && errors.push(this.$t("error.minChar"));
      !this.$v.chef.password.containsLowercase &&
        errors.push(this.$t("error.containsLowercase"));
      !this.$v.chef.password.containsUppercase &&
        errors.push(this.$t("error.containsUppercase"));
      !this.$v.chef.password.containsNumber &&
        errors.push(this.$t("error.containsNumber"));
      !this.$v.chef.password.containsSpecial &&
        errors.push(this.$t("error.containsSpecial"));
      this.chef.password !== this.chef.passwordConfirm &&
        this.$v.chef.password.$dirty &&
        this.$v.chef.passwordConfirm.$dirty &&
        errors.push(this.$t("error.passwordShouldMatch"));
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-=_+!@#$%^&*(),.?"':;{/}[\]|<~>§±`\\]).{8,24}$/.test(
        this.chef.password
      ) && errors.push(this.$t("common.passwordHint"));
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.chef.password.$dirty) return errors;
      !this.$v.chef.password.required &&
        errors.push(this.$t("error.isRequired"));

      !this.$v.chef.password.minLength && errors.push(this.$t("error.minChar"));
      this.chef.password !== this.chef.passwordConfirm &&
        this.$v.chef.password.$dirty &&
        this.$v.chef.passwordConfirm.$dirty &&
        errors.push(this.$t("error.passwordShouldMatch"));
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-=_+!@#$%^&*(),.?"':;{/}[\]|<~>§±`\\]).{8,24}$/.test(
        this.chef.passwordConfirm
      ) && errors.push(this.$t("common.passwordHint"));
      return errors;
    }
  },
  methods: {
    allowedChar($event) {
      if (
        !new RegExp("^[\u0621-\u064Aa-zA-Z]$").test(
          String.fromCharCode($event.which)
        )
      ) {
        $event.preventDefault();
      }
    },
    onlyNumbers($event) {
      if (!new RegExp("^[0-9]$").test(String.fromCharCode($event.which))) {
        $event.preventDefault();
      }
    },
    open() {
      this.value = true;
    },
    async clearForm() {
      this.chef = {
        en: {
          firstName: "",
          lastName: ""
        },
        idNumber: "",
        idExpiry: "",
        mobile: "",
        email: "",
        city: "",
        password: "",
        passwordConfirm: "",
        agreeToTermsAndConditions: false
      };
      this.$v.$reset();
      this.value = false;
    },
    async chefSignUp() {
      this.$v.chef.$touch();
      if (!this.$v.chef.$invalid) {
        const formData = new FormData();
        formData.append("firstNameEN", this.chef.en.firstName);
        formData.append("lastNameEN", this.chef.en.lastName);
        formData.append("firstNameAR", this.chef.en.firstName);
        formData.append("lastNameAR", this.chef.en.lastName);

        formData.append("idNumber", this.chef.idNumber);
        formData.append("idExpiry", this.chef.idExpiry);
        formData.append("email", this.chef.email);
        formData.append("city", this.chef.city);
        formData.append("password", this.chef.password);
        formData.append("passwordConfirm", this.chef.passwordConfirm);
        formData.append(
          "agreeToTermsAndConditions",
          this.chef.agreeToTermsAndConditions
        );
        formData.append("mobile", this.chef.mobile);

        this.uploading = true;
        this.role = "CHEF";
        try {
          await AuthService.registerChef(formData);
          this.$root.$alert.success({
            message: this.$t("success.registerChef")
          });
          this.clearForm();
        } catch {
        } finally {
          this.uploading = false;
        }
      } else {
        this.$refs.signupForm.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  validations: {
    chef: {
      en: {
        firstName: {
          required,
          maxLength: maxLength(50),
          nameValid: function(value) {
            return /^[\u0621-\u064Aa-zA-Z]{1,50}$/.test(value);
          }
        },
        lastName: {
          required,
          maxLength: maxLength(50),
          nameValid: function(value) {
            return /^[\u0621-\u064Aa-zA-Z]{1,50}$/.test(value);
          }
        }
      },
      idNumber: {
        required,
        maxLength: maxLength(12),
        idValid: function(value) {
          return /^[0-9]{10}$/.test(value);
        }
      },
      idExpiry: { required },
      mobile: {
        required,
        maxLength: maxLength(12),
        minLength: minLength(12),
        numeric,
        mobileValid: function(value) {
          return /^966[0-9]{9}$/.test(value);
        }
      },
      email: { required, email },
      city: { required },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(24),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function(value) {
          return /[!@#$%^&*(),.?"':;{/}[\]|<~>§±`]/.test(value);
        }
      },
      passwordConfirm: { sameAsPassword: sameAs("password") },
      agreeToTermsAndConditions: {
        checked(val) {
          return val;
        }
      }
    }
  },
  created() {
    this.$root.$chefSignUpForm = {
      open: this.open
    };
  }
};
</script>
